// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import React, { useEffect } from "react";
// import Popup from "layouts/profile/components/ListPopup/Popup"
import Popup from "../ListPopup/Popup";
import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import mtn from "assets/images/logos/mtn.png";
import telkom from "assets/images/logos/telkom.png";
import vodacom from "assets/images/logos/vodacom.png";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
import "../CampaignCard/style.css";
import CampaignProvider from "../../../../providers/CampaignProvider";
import ApprovalProvider from "providers/ApprovalProvider";

const today = new Date();
const time = today.getDate() + ":" + today.getMonth() + ":" + today.getFullYear();

function CampaignsCard() {

  const [buttonPopup, setButtonPopup] = useState(false);
  const [userCampaigns, setUserCampaigns] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsDisabled, setCampaignsDisabled] = useState(true);

  let [header] = useState("campaign");

  const fetchUserCampaign = async () => {
    let res = await CampaignProvider.getUserCampaigns(localStorage.getItem('userPrincipalName'));
    if (res.length > 0) {
      setUserCampaigns(res);
    }
  };

  const fetchCampaigns = async () => {
    let res = await CampaignProvider.getCampaigns();
    setCampaigns(res);
  };

  const changeHandler = () => {
    fetchButtonConfig();
  }

  useEffect(() => {
    fetchUserCampaign();
  }, [time]);

  useEffect(() => {
    fetchCampaigns();
    fetchButtonConfig();
  }, [time]);

  const fetchButtonConfig = async () => {
    let campaignsAllow = await ApprovalProvider.disallowActions('UPDATE CAMPAIGNS');
    if (campaignsAllow?.ActionValue == 0) {
      setCampaignsDisabled(false);
    }
    // else {
      // setCampaignsDisabled(false);
    // }
  }

  const renderCampaigns = userCampaigns.map(({ Id, Type, title }) => (
    <div key={Id} sx={{ height: "100%" }}>
      <SuiBox component="li" display="flex" alignItems="center" py={1} mb={1}>
        <SuiBox mr={2}>
          <SuiAvatar
            src={
              Type?.toLowerCase().includes("mtn")
                ? mtn
                : Type?.toLowerCase().includes("telkom")
                  ? telkom
                  : Type?.toLowerCase().includes("vodacom")
                    ? vodacom
                    : null
            }
            alt="something here"
            variant="rounded"
            shadow="md"
          />
        </SuiBox>

        <SuiBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
          <SuiTypography variant="button" fontWeight="medium">
            {Type}
          </SuiTypography>
          <SuiTypography variant="caption" color="text">
            {title}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </div>
  )
  );

  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox p={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <SuiBox component="li" display="flex" alignItems="center" py={0} mb={5}>
            <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Campaigns
            </SuiTypography>
            <SuiBox ml="auto">
              <SuiButton onClick={() => setButtonPopup(true)}
                component={Link}
                variant="text"
                color="info"
                disabled={campaignsDisabled}
              >
                Update Campaign
              </SuiButton>
            </SuiBox>
          </SuiBox>
          <SuiBox className="campaignContainer">{renderCampaigns}</SuiBox>
        </SuiBox>
        <Popup
          trigger={buttonPopup}
          onChange={changeHandler}
          setTrigger={setButtonPopup}
          header={header}
          data={campaigns}
          setData={userCampaigns}
        ></Popup>
      </SuiBox>
    </Card>
  );
}

// Typechecking props for the CampaignsCard
CampaignsCard.propTypes = {
  // title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CampaignsCard;
