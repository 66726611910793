import React, { useEffect, useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { CSVLink } from 'react-csv';
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import FileUpload from "../../User/FileUpload.jsx";
import requesterRoleConfig  from '../../../static/configs/requesterRoleConfig.json';
import { AiOutlineClose } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import Card from "@mui/material/Card";
import ApprovalProvider from "../../../providers/ApprovalProvider.js";
import UserConfirmationList from "../../User/UserConfirmationList";
import { useFormContext } from 'react-hook-form';
import { alphaAndNumericWithSpaces, number } from "../../../helpers/regex";
import { has, uniq } from 'lodash';
import { ImDownload2 } from 'react-icons/im';

import {
  useSoftUIController,
  setRequestor,
} from "context";
import { isEmpty } from "lodash";
import templates from '../../../static/templates/fileUpload.json'

const UserMovementModal = ({view, setCurrentModal, categoryType, requestType}) => {
  const { getValues, setValue } = useFormContext();
  const requiredFields = ['EmployeeName', 'EmployeeCode', 'Role'];
  const [controller, dispatch] = useSoftUIController();
  const { requestor } = controller;
  const Fields = [
    {
      name: 'EmployeeName',
      regex: alphaAndNumericWithSpaces,
      required: true
    },
    {
      name: 'EmployeeNumber',
      regex: number,
      required: true
    },
    {
      name: 'Role',
      options: ['Junior Manager', 'General Manager', 'Head of Sales'],
      required: true
    }
  ];
  const approverFileTemplate = templates.approver;

  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [stepLimit, setStepLimit] = useState(0);
  const [step, setStep] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [invalidRecords, setInvalidRecords] = useState([]);
  const [showInvalidRecords, setShowInvalidRecords] = useState(false);
  const [requestorObject, setRequestorObject] = useState({});

  
  const handleStep = (direction) => {
    if (step === stepLimit) {
      setCurrentModal({});
      return;
    }

    if(direction === '+'){
      setStep(step + 1);
    }
    else if(direction === '-'){
      if(step === 0) return;
      setStep(step - 1);
    }
  };

  const handleNextStep = async () => {
    if(view === 'file-upload'){
      switch (step) {
        case 0:
          setFileUpload();
          break;
  
        case 1:
          saveToContext();
          break;
    
        default:
          setCurrentModal({});
          break;
      }
    }
	};

  const saveToContext = () => {
    if(view === 'file-upload'){
      setValue('moveApprover', requestorObject);
      setRequestor(dispatch, requestorObject);
      setCurrentModal({});
    }
  }

  const setFileUpload = () => {
    if(invalidRecords.length === 0){
      handleStep('+');
    }
  }

  const fieldValidation = async(objects) => {
    let invalidations = [];
    let approverEmpNumbers = [];

    setIsLoading(true);

    for (let i = 0; i < objects.length; i++) {      
      const object = objects[i];
      approverEmpNumbers.push(object['EmployeeNumber']);

      for (let j = 0; j < Fields.length; j++) {
        const { name, regex, options, ruleMatch, required } = Fields[j];
        if (!object.hasOwnProperty(name)) {
            invalidations.push({description: `Record/Row ${i + 2} => Field ${name} missing.`});
            return invalidations;
        }

        if(isEmpty(object[name]) && required == false){
          continue;
        }

        if (isEmpty(object[name]) || (regex && !regex.test(object[name]))) {
            invalidations.push({description: `Record/Row ${i + 2} => Field ${name} does meet the format requirements.`});
        }

        if(options && !options.includes(object[name])){
          invalidations.push({description: `Record/Row ${i + 2} => Field ${name} must be one the following: ${JSON.stringify(options)}.`});
        }

        if(ruleMatch && ruleMatch !== object[name]){
          invalidations.push({description: `Record/Row ${i + 2} => User ${name} must be ${userType} for an ${userType} Onboard Request.`});
        }
      }
    }

    if(approverEmpNumbers.length > 0){
      uniq(approverEmpNumbers).length !== approverEmpNumbers.length ?
      invalidations.push({description: `Column EmployeeNumber => Duplicate Employee Numbers`}) : null;
      // invalidations.push({description: `Column EmployeeNumber => No employee numbers detected in file`});
    }

    if(  requestor && (!isEmpty(requestor?.initiateMove) || !isEmpty(requestor?.initiateRemove) || !isEmpty(requestor?.initiateAdd))){

      //Checks Requestor is on list
      const requestorEmployeeNumber = localStorage.getItem("EmployeeNumber");
      const requestorCheck = objects.filter((approver) => approver.EmployeeNumber === requestorEmployeeNumber);
      if(requestorCheck.length === 0){
        invalidations.push({description: `The requestor should be in the approvers list`})
      }

      if(getValues("MovementRole") !== "Agent"){
        if(requestor?.initiateMove){
          for (const request of requestor?.initiateMove ) {
            const newManagerCheck = objects.filter((approver) => approver.EmployeeNumber === request.ManagerEmployeeNumber);
            if(newManagerCheck.length === 0){
              invalidations.push({description: `${request?.ManagerName} (${request?.ManagerEmployeeNumber}) should be an appover on the list.`})
            }
          }
        }
        if(requestor?.initiateAdd){
            const newManagerCheck = objects.filter((approver) => approver.EmployeeNumber === requestor?.initiateAdd[0].ManagerEmployeeNumber);
            if(newManagerCheck.length === 0){
              invalidations.push({description: `${requestor?.initiateAdd[0].ManagerUserPrincipalName} : (${requestor?.initiateAdd[0].ManagerEmployeeNumber}) should be an appover on the list.`})
            }
        }
        else if(requestor?.initiateRemove){

        }
      }

    
      try {
        const res = await ApprovalProvider.isValidApprovers(approverEmpNumbers);
        console.log('APPROVERS RES:', res);
        if(res.status === 'invalid' && res.data && res.data.length > 0){
          for (const employeeNumber of res.data) {
            invalidations.push({description: `(EmpNumber: ${employeeNumber}) is not a valid approver.`})
          }
        }

        if(res.status !== 'success'){
          invalidations.push({description: `Could not Validate File. Please get in contact with Support Team.`})
        }
      } catch (error) {
        invalidations.push({description: `Could not Validate File. Please get in contact with Support Team.`})
      }
    }else{
      setInvalidRecords([{description: `Please complete the previous input options first before selecting approvers.`}])
    }

    setIsLoading(false);
    return invalidations; // All objects contain all required properties with valid values
  }

  const fileValidationCheck = async(data) => {
    let invalidations = [];
    setInvalidRecords([]);
    setIsValid(false);

    
    //Validate Required Headers
    for (let field of Fields) {
      if (!has(data[0], field.name)) {
        invalidations.push({description: `Invalid file. Missing required header ${field.name}`});
      }
    }

    if(invalidations && invalidations.length > 0){ setInvalidRecords(invalidations); return; }

    // 2) Validate Record Format
    const invalidationsResp = await fieldValidation(data);
    if(invalidationsResp.length > 0){ setInvalidRecords(invalidationsResp); return; }

    setIsValid(true);
    setRequestorObject({'moveApprover': data});
  }



  useEffect(() => {
    const getData = async() => {
      if(view === 'file-upload'){
        setStepLimit(1);

        switch (step) {
          case 0:
            setTitle('Who are the approvers for this request?');
            break;
  
          case 1:
            setTitle('Approvers Confirmation:');
            break;
        
          default:
            setCurrentModal({});
            break;
        }
      }
    }
    getData();
  }, [step])

  return (
    <div>
      <SuiBox mb={1} alignItems="baseline" justifyContent="space-between" display="flex" className="header">
        <SuiTypography pb={2} variant="h6">{title}</SuiTypography>
        {approverFileTemplate && step === 0 && <CSVLink separator=";" filename={`approver-template.csv`} data={approverFileTemplate}>
        <SuiButton color="info"  >
          <ImDownload2 /> &nbsp; Download Template
        </SuiButton>
        </CSVLink>}
      </SuiBox>

      <SuiBox>
        <div className="form-group">
          <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
            {view === "file-upload" && (
              <>
                {step === 0 && (
                  <FileUpload
                    isValid={isValid}
                    isLoading={isLoading}
                    invalidRecords={invalidRecords}
                    fileValidationCheck={fileValidationCheck}
                    handleNextStep={handleNextStep}
                />
                )}
                {step === 1 && (
                  <UserConfirmationList 
                    className="file-upload-block" 
                    profiles={requestorObject?.moveApprover.map((approver) => ({
                      fullName: approver.EmployeeName,
                      employeeNumber: approver.EmployeeNumber,
                    }))}
                    handleNextStep={handleNextStep}
                  />
                )}
              </>
            )}
          </SuiBox>
        </div>
      </SuiBox>

      <div className="button-section">
        {invalidRecords.length > 0 && <SuiBox className="error-tag" onClick={() => setShowInvalidRecords(true)}>
          <BiError />
          <SuiTypography color="error" variant="caption">Validation Errors Found</SuiTypography>
        </SuiBox>}
        <SuiTypography
          onClick={() => {
            setCurrentModal({});
          }}
          className="cancel-btn"
        >
          Cancel
        </SuiTypography>
        {/* <SuiButton
          form="modal-form"
          className="action-btn"
        >
          Continue
        </SuiButton> */}
        <button disabled={isSearching} form="modal-form" className="btn btn-orange standard">
          Continue
				</button>
      </div>
      {showInvalidRecords && 
      <Card className="file-error-card">
        <SuiBox className='header'>
          <SuiTypography variant="h6">Validation Errors</SuiTypography>
          <AiOutlineClose className="close-btn" onClick={() => {setShowInvalidRecords(false)}}/>
        </SuiBox>
        <SuiBox className="error-content" sx={{ maxHeight: '200px', minWidth: '400px' }} >
          {invalidRecords.length > 0 && invalidRecords.map((record, index) => (
            <SuiTypography key={index} color="error" variant="overline">{record.description}</SuiTypography>
          ))}
        </SuiBox>
      </Card>}
    </div>
  );
}

export default UserMovementModal