import { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DefaultComponentLoader from "components/Loaders/DefaultComponentLoader";
import requesterErrorMessages from '../../static/messages/errorMessages.json';
import { setOpenRequestor, setRequestor, useSoftUIController} from "context";
import requesterConfig from '../../static/configs/requesterConfig.json';
import TeamMembersProvider from "../../providers/TeamMembersProvider";
import RequestorRoot from "examples/Requestor/RequestorRoot";
import { FormProvider, useForm } from 'react-hook-form';
import { FaRegCheckCircle } from "react-icons/fa";
import DatePicker from "react-datepicker";
import useAlert from "../../common/useAlert";
import { isEmpty } from "lodash";

//Providers
import DisplayBlockRenderer from '../../components/ContentBlocks/index';
import MovementReasonProvider from '../../providers/MovementReasonProvider';

// UI components
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import Select from "react-select";
import SuiInput from "components/SuiInput";

function Requestor() {
  const [controller, dispatch] = useSoftUIController();
  const { requestor, openRequestor, fixedNavbar } = controller;
  const [currentModal, setCurrentModal] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState({
    show: false,
    message: ''
  });
  
  //VARIABLES FOR CONFIG
  const config = requesterConfig;
  const methods = useForm();
  const { alert } = useAlert();
  const { formState: { errors }, register, setValue, getValues, clearErrors, unregister } = methods;
  const configDefaultOption = {categoryType: "teamManagement", requestType: 'teamOrAgentMove'};
  const actionTypeConfig = useRef(null);
  const [categoryType, setCategoryType] = useState('');
  const [requestType, setRequestType] = useState('');
  const [errorMessages, setErrorMessages] = useState(null);
  const [formFieldOptions, setFormFieldOptions] = useState({});
  const roleId = localStorage.getItem("roleId") || '2';


  const initialiseActionTypeConfig =({categoryType, requestType}) => {
      setIsLoading(true);
      if(categoryType && requestType){
        const requestConfig = config[categoryType].requests[requestType];

        if(!isEmpty(requestConfig)){
          actionTypeConfig.current = requestConfig;

          for (var field in requestConfig.formFields) {
            if (requestConfig.formFields.hasOwnProperty(field) && requestConfig.formFields[field].inputType === 'select') {
                handleFormFieldSelectOptions({field: requestConfig.formFields[field]});
            }
          }
        }
      }
      setIsLoading(false);
  };

  const onChangeRequestType = async(requestType) => {
    setRequestType(requestType);
  }

  const onChangeCategoryType = (category) => {
    setCategoryType(category);
  }

  const handleCloseRequestor = () => setOpenRequestor(dispatch, false);

  const approveMoveRequest = async(requestId) => {
    const response = await TeamMembersProvider.approveMovementRequest({
      Id: requestId,
      ApproverComment: "Default Comment",
      ActionedBy: localStorage.getItem("EmployeeNumber")
    });

    if (response && response.status=='success') {
      successPrompt();
      localStorage.removeItem("teamMembers");

    }else{
      alert({
        icon:'error',
        title: 'Request created, instant approval failed.',
      });
    }
  }

  const approveCreateRequest = async(requestId) => {
    const response = await TeamMembersProvider.approveEmployeeCreationRequest({
      Id: requestId,
      ApproverComment: "Default Comment",
      ActionedBy: localStorage.getItem("EmployeeNumber")
    });

    if (response && response.status=='success') {
      successPrompt();
      localStorage.removeItem("teamMembers");

    }else{
      alert({
        icon:'error',
        title: 'Request created, instant approval failed.',
      });
    }
  }

  const approveRemoveRequest = async(requestId) => {
    const response = await TeamMembersProvider.approveEmployeeRemovalRequest({
      Id: requestId,
      ApproverComment: "Default Comment",
      ActionedBy: localStorage.getItem("EmployeeNumber")
    });

    if (response && response.status=='success') {
      successPrompt();
      localStorage.removeItem("teamMembers");

    }else{
      alert({
        icon:'error',
        title: 'Request created, instant approval failed.',
      });
    }
  }

  // const onError = (errors, e) => console.log(errors, e);

  const successPrompt = () => {
    alert({
      icon:'success',
      title: 'Request Successfully Submitted with Instant Approval',
    });
    setRequestor(dispatch, {})
    unregister();
    clearErrors();
    setTimeout(handleCloseRequestor(), 2000);
  }

  const onSubmit = async () => {
    setLoader({show: true, message: 'Submitting Request'});
   
    if(requestType === 'teamOrAgentMove'){
            
      if(localStorage.getItem("EmployeeNumber") === null){
        return;
      }

      let requestPayload = {
        RequestType: "UPDATE CAMPAIGNS AND MANAGER",
        RequestDescription: "portal-request",
        CreatedBy: "portal",
        RequesterUserEmployeeNumber: localStorage.getItem("EmployeeNumber"),
        UserHierarchyMovementReasonId: requestor.moveReason,
        ApproverUserEmployeeNumbers: requestor.moveApprover.map(employee => employee.EmployeeNumber),
        EffectiveDate: requestor.effectiveDate,
        EmployeeMovements: [{
          UserEmployeeNumbers: requestor.initiateMove.map(obj => obj.EmployeeNumber),
          CampaignCodes: requestor.initiateMove[0].Campaigns.map(campaign => campaign.CampaignCode),
          ManagerUserEmployeeNumber: requestor.initiateMove[0].ManagerEmployeeNumber
        }]
      }

      if(getValues("MovementRole") === "Agent"){
        const response = await TeamMembersProvider.createBulkAgentMovement(requestPayload);
        if (response && response?.status=='success' && response?.data?.RequestId) {
          await approveMoveRequest(response.data.RequestId);
          setLoader({show: false});

        }else{
          alert({
            icon:'error',
            title: 'Submission Failed.',
            text: response?.message || 'Something Went Wrong :('
          });
        }
      }else if(getValues("MovementRole").includes("Manager")){
        const response = await TeamMembersProvider.createBulkManagerMovement(requestPayload);
        if (response && response?.status=='success' && response?.data?.RequestId) {
          await approveMoveRequest(response.data.RequestId);
          setLoader({show: false});
          
        }else{
          alert({
            icon:'error',
            title: 'Submission Failed.',
            text: response?.message || 'Something Went Wrong :('
          });
        }
      }
    }
    if(requestType === 'teamOrAgentIn'){

      let requestPayload = {
        RequestType: "NEW EMPLOYEES ONBOARDING",
        RequestDescription: "portal-request",
        CreatedBy: "portal",
        RequesterUserEmployeeNumber: localStorage.getItem("EmployeeNumber"),
        UserHierarchyMovementReasonId: requestor.moveReason,
        ApproverUserEmployeeNumbers: requestor.moveApprover.map(employee => employee.EmployeeNumber),
        NewEmployees: [],
        EffectiveDate: requestor.effectiveDate
    }

      const Employees = requestor.initiateAdd.map(emp => ({
        UserName: emp.UserName,
        FullName: emp.Name + ' ' + emp.Surname,
        UserPrincipalName: emp.UserPrincipalName,
        MobileNumber: emp.MobileNumber,
        EmployeeNumber: emp.EmployeeNumber,
        DiallerUserName: emp.UserName?.split("/")[1] || null,
        ManagerUserEmployeeNumber: emp.ManagerEmployeeNumber,
        Application: 'Molo Sales',
        Role: getValues("MovementRole"),
        CampaignCodes: emp.CampaignCodes || [],
      }));
      
      requestPayload.NewEmployees = Employees;
      
      const response = await TeamMembersProvider.createBulkEmployeeCreationRequest(requestPayload);
      if (response && response?.status=='success' && response?.data?.RequestId) {
        await approveCreateRequest(response.data.RequestId);
        setLoader({show: false});

      }else{
        alert({
          icon:'error',
          title: 'Submission Failed.',
          text: response?.message || 'Something Went Wrong :('
        });
      }
    }
    if(requestType === 'teamOrAgentOut'){
      let requestPayload = null;

      for (let index = 0; index < requestor.initiateRemove.length; index++) {
        const childObj = requestor.initiateRemove[index];
        const requestObj = {
            RequestType: "EMPLOYEES OFFBOARDING",
            RequestDescription: "portal-request",
            CreatedBy: "portal",
            RequesterUserEmployeeNumber: localStorage.getItem("EmployeeNumber"),
            UserHierarchyMovementReasonId: requestor.moveReason,
            ApproverUserEmployeeNumbers: requestor.moveApprover.map(employee => employee.EmployeeNumber),
            OffboardEmployeeNumbers: [childObj.EmployeeNumber],
            EffectiveDate: requestor.effectiveDate
        }
        
        requestPayload = requestObj;
      }

      const response = await TeamMembersProvider.createBulkEmployeeRemovalRequest(requestPayload);
        if (response && response?.status=='success' && response?.data?.RequestId) {
          await approveRemoveRequest(response.data.RequestId);
          setLoader({show: false});

        }else{
          alert({
            icon:'error',
            title: 'Submission Failed.',
            text: response?.message || 'Something Went Wrong :('
          });
        }
    }
    setLoader({show: false});
  };

  const ErrorMessage = props => {
    const { name, errors } = props;   

    const settingError = errors => {
      if (errors[name] && errors[name]['type'] === 'custom' && errors[name]['message']) 
        return <p className="error msg pad">{errors[name]['message']}</p>;
      if (errors[name] && errors[name]['type'] === 'required')
        return <p className="error msg pad">{errorMessages[name]['required']}</p>;
      if (errors[name] && errors[name]['type'] === 'pattern')
        return <p className="error msg pad">{errorMessages[name]['invalid']}</p>;
      if (errors[name] && errors[name]['type'] === 'validate')
        return <p className="error msg pad">{errorMessages[name]['validate'] || errorMessages[name]['invalid']}</p>;
      if (errors[name] && errors[name]['type'] === 'minLength')
        return <p className="error msg pad">{errorMessages[name]['minLength']}</p>;
      if (errors[name] && errors[name]['type'] === 'maxLength')
        return <p className="error msg pad">{errorMessages[name]['maxLength']}</p>;
      return '';
    };

    return settingError(errors);
  };

  const handleFormFieldSelectOptions = ({field, setDefault}) => {
    let temp = [];
    setIsLoading(true);
    
    if(isEmpty(field.options) && !setDefault){
      temp = dynamicSelectOptionsSetter({fieldName : field.name});
    }

    //handles mapping if description exists
    if(field.options[0] && !isEmpty(field.options[0].description)){

      if (setDefault) {
        setIsLoading(false);
        return {
          value: field.defaultOption?.value,
          label: (
            <div>
              <label>{field.defaultOption?.label}</label>
              <small className="text-muted">{field.defaultOption?.description}</small>
            </div>
          )
        }
      }

      field.options.map((option) => {
        temp.push({
          value: option.value,
          label: (
            <div>
              <label>{option.label}</label>
              <small className="text-muted">{option.description}</small>
            </div>
          )
        });
      })
    }
    //Else basic remapping
    else{
      if (setDefault) {
        setIsLoading(false);
        return {
          value: field.defaultOption?.value,
          label: field.defaultOption?.label,
        }
      }

      field.options.map((option) => {
        temp.push({
          value: option.value,
          label: option.label,
        });
      })
    }
    setFormFieldOptions((prevState) => ({
      ...prevState,
      [field.name]: temp
    }))

    setIsLoading(false);
  }

  const dynamicSelectOptionsSetter = async({fieldName}) => {
    setIsLoading(true);

    let temp = [];
    switch (fieldName) {
      case 'moveReason':

      if (!isEmpty(actionTypeConfig.current)){
        let tempActionTypeConfig = actionTypeConfig.current;
        const reasonOptions = await MovementReasonProvider.getMovementReasons(tempActionTypeConfig?.reasonsTag);
        reasonOptions.map((option) => {
          temp.push({
            value: option.Id,
            label: option.Value,
          });
        })
        setIsLoading(false);
        setFormFieldOptions((prevState) => ({
          ...prevState,
          [fieldName]: temp
        }))
      }
        break;
    
      default:
        break;
    }
  }

  useEffect(() => {
    if(openRequestor){
      if(categoryType && requestType){
        initialiseActionTypeConfig({categoryType, requestType});
      }else{
        setCategoryType(configDefaultOption.categoryType);
        setRequestType(configDefaultOption.requestType);
        initialiseActionTypeConfig(configDefaultOption);
      }
    }
  }, [requestType, openRequestor])

  useEffect(() => {
    if(openRequestor){
      unregister();
      clearErrors();

      setErrorMessages(requesterErrorMessages['teamManagement'].requests[requestType]);
      setRequestor(dispatch, {'clear': {}});
    }
  }, [actionTypeConfig.current])

  return (
    <>
    {openRequestor && loader && loader.show && <DefaultComponentLoader message={loader.message} />}
    <RequestorRoot modalactive={currentModal} className="requestor" variant="permanent" ownerState={{ openRequestor }}>
      {openRequestor &&
      <>
        <FormProvider {...methods}>
          {!isEmpty(currentModal) && 
          <DisplayBlockRenderer 
            requestType={requestType} 
            categoryType={categoryType} 
            currentModal={currentModal} 
            setCurrentModal={setCurrentModal}
          />}
          <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
            <SuiBox
              sx={{
                maxWidth: 800,
                minWidth: 800,
                margin: 'auto',
              }}
            >
              <SuiBox
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={3}
                pb={0.8}
                px={3}
              >
                <SuiBox>
                  <SuiTypography fontWeight="bold" variant="h5">Log a New Request</SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    Welcome! You can log a request using options provided.
                  </SuiTypography>
                </SuiBox>

                <Icon
                  sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                    fontSize: `${size.md} !important`,
                    fontWeight: `${fontWeightBold} !important`,
                    stroke: dark.main,
                    strokeWidth: "2px",
                    cursor: "pointer",
                    mt: 2,
                  })}
                  onClick={handleCloseRequestor}
                >
                  close
                </Icon>
              </SuiBox>

              <Divider />

              <SuiBox pt={1.25} pb={3} px={3}>
                <SuiBox mb={2} lineHeight={1}>
                  <SuiTypography variant="h6">Raise a request about</SuiTypography>
                  <SuiTypography variant="button" color="text" fontWeight="regular">
                    Choose type of request you want to log.
                  </SuiTypography>
                  <SuiBox mb={2} mt={1}>
                    <Select
                      isDisabled
                      classNamePrefix="custom-select"
                      onChange={(e) => {onChangeCategoryType(e.value)}}
                      className="select-control select-one"
                      value={{
                        value: 'teamManagement',
                        label: 'Team Management',
                      }}
                      options={[{
                        value: 'teamManagement',
                        label: 'Team Management',
                      }]}
                    />
                  </SuiBox>
                </SuiBox>

                <SuiBox mt={5} mb={2} lineHeight={1}>
                  <SuiTypography variant="h6">What can we help you with?</SuiTypography>
                  <SuiBox mb={2} mt={1}>
                    <Select
                      isSearchable={false}
                      classNamePrefix="custom-select"
                      className="select-control select-one select-with-html"
                      onChange={(e) => {onChangeRequestType(e.value)}}
                      defaultValue={{
                        value: 'teamOrAgentMove',
                        label: (
                          <div>
                            <label>Request user movement</label>
                            <small className="text-muted">Create a request to move a user or team</small>
                          </div>
                        )
                      }}
                      options={roleId === '2'? [{
                        value: 'teamOrAgentMove',
                        label: (
                          <div>
                            <label>Request user movement</label>
                            <small className="text-muted">Create a request to move a user or team</small>
                          </div>
                        )
                      }
                    ] : [{
                      value: 'teamOrAgentMove',
                      label: (
                        <div>
                          <label>Request user movement</label>
                          <small className="text-muted">Create a request to move a user or team</small>
                        </div>
                      )
                    },
                    {
                      value: 'teamOrAgentIn',
                      label: (
                        <div>
                          <label>Request user creation</label>
                          <small className="text-muted">Create a request to create new user or team</small>
                        </div>
                      )
                    },
                    {
                      value: 'teamOrAgentOut',
                      label: (
                        <div>
                          <label>Request user removal</label>
                          <small className="text-muted">Create a request to remove a user</small>
                        </div>
                      )
                    }
                  ]}
                    />
                  </SuiBox>
                </SuiBox>
                <SuiBox className={`dynamic-formfields row`}>
                  {actionTypeConfig.current && actionTypeConfig.current.formFields && Object.keys(actionTypeConfig.current.formFields).map((field, index) => { 
                      let formFields = actionTypeConfig.current.formFields;
                      let tempValue = null;

                      switch (formFields[field]['inputType']) {
                        
                        case "select":
                            tempValue = formFields[field].name;
                            return (
                                <SuiBox className={`${errors[formFields[field].name]} col-${formFields[field].col || 'col-12'}`} mt={4} mb={2} lineHeight={1} md={formFields[field].col || 12} key={index}>
                                  <SuiTypography variant="h6">{formFields[field].title}</SuiTypography>
                                  {formFields[field]?.subTitle && 
                                    <SuiTypography variant="button" color="text" fontWeight="regular">{formFields[field]?.subTitle}</SuiTypography>
                                  }
                                  <SuiBox position="relative">
                                    <Select
                                      key={`${requestType}-${formFields[field].id}`}
                                      isSearchable={formFields[field].searchable || false}
                                      isMulti={formFields[field].multiSelect}
                                      label={`${formFields[field].label}`}
                                      type={`${formFields[field].type}`}
                                      id={`${formFields[field].id}`}
                                      options={formFieldOptions[formFields[field].name]}
                                      isDisabled={isLoading}
                                      placeholder={`${formFields[field].placeholder}`}
                                      defaultValue={() => handleFormFieldSelectOptions({field:formFields[field], setDefault: true})}
                                      classNamePrefix="custom-select"
                                      className="select-control select-one select-with-html"
                                      register={register(formFields[field].name, formFields[field].validation)}
                                      onChange={item => {
                                        if(formFields[field]?.hasModal){
                                          setCurrentModal({modalKey: formFields[field].id, view: item.value});                                          

                                        }else{
                                          setValue(formFields[field].name, item.value);
                                          setRequestor(dispatch,{[tempValue]: item.value});
                                        }
                                      }}
                                    />
                                    
                                    {requestor[formFields[field].name] && <FaRegCheckCircle 
                                      style={{
                                        position: 'absolute',
                                        top: '50%',
                                        transform:'translateY(-50%)',
                                        right: '-3rem',
                                        width: '30px',
                                        height: '30px',
                                        color: 'green'
                                      }} 
                                    />}
                                  </SuiBox>
                                  <ErrorMessage name={formFields[field].name} errors={errors} />
                                </SuiBox>
                        )

                        case "textfield":
                          tempValue = formFields[field].name;
                          return (
                              <SuiBox className={`col-${formFields[field].col || 'col-12'}`} mt={4} mb={2} lineHeight={1} key={index}>
                                <SuiTypography sx={{paddingBottom: '0.5rem'}} variant="h6">{formFields[field].title}</SuiTypography>
                                {formFields[field]?.subTitle && 
                                  <SuiTypography variant="button" color="text" fontWeight="regular">{formFields[field]?.subTitle}</SuiTypography>
                                }
                                <SuiInput
                                  
                                  label={`${formFields[field].label}`}
                                  type={`${formFields[field].type}`}
                                  id={`${formFields[field].id}`}
                                  name={`${formFields[field].name}`}
                                  disabled={isLoading}
                                  placeholder={`${formFields[field].placeholder}`}
                                  // defaultValue={() => handleFormFieldSelectOptions({field:formFields[field], setDefault: true})}
                                  register={register(formFields[field].name, formFields[field].validation)}
                                  onChange={item => {
                                    if(formFields[field]?.hasModal){
                                      setCurrentModal({modalKey: formFields[field].id, view: item.value});

                                    }else{
                                      setValue(formFields[field].name, item.value);
                                      setRequestor(dispatch,{[tempValue]: item.value});
                                    }
                                  }}
                                />
                                <ErrorMessage name={formFields[field].name} errors={errors} />
                              </SuiBox>
                        )
                        
                        case "disclaimer":
                        tempValue = formFields[field].name;
                        return (
                            <SuiBox className={`col-${formFields[field].col || 'col-12'}`} mt={4} mb={2} lineHeight={1} key={index}>
                              <SuiTypography 
                                sx={{paddingBottom: '0.5rem'}} 
                                color="error" fontWeight="regular" 
                                variant="button">
                                {formFields[field].title ? formFields[field].title + ': ' : ''}
                              </SuiTypography>
                              {formFields[field]?.description && 
                                <SuiTypography variant="button" color="text" fontWeight="regular">{formFields[field]?.description}</SuiTypography>
                              }
                            </SuiBox>
                        )

                        case "datepicker":
                          return (
                            <SuiBox className={`col-${formFields[field].col || 'col-12'}`} mt={4} mb={2} lineHeight={1} key={index}>
                              <SuiTypography variant="h6">{formFields[field].title}</SuiTypography>
                              <SuiBox mb={2} mt={1}>
                                <SuiBox position="relative">
                                  <DatePicker 
                                    showIcon={formFields[field]?.showIcon}
                                    className="form-control"
                                    selected={requestor[formFields[field].name] || new Date()} 
                                    timeInputLabel={formFields[field]?.timeInputLabel || "Time:"}
                                    dateFormat={formFields[field]?.dateFormat || "dd MMMM yyyy"}
                                    showTimeInput={formFields[field]?.showTimeInput || false}
                                    isClearable={formFields[field]?.isClearable || false}
                                    minDate={new Date().setMonth(new Date().getMonth() - 3)}
                                    maxDate={new Date().setMonth(new Date().getMonth() + 3)}
                                    register={register(formFields[field].name, formFields[field].validation)}
                                    onChange={item => {
                                      setValue(formFields[field].name, item);
                                      setRequestor(dispatch,{[formFields[field].name]: item});
                                    }}
                                  /> 
                                  {requestor[formFields[field].name] && <FaRegCheckCircle 
                                    style={{
                                      position: 'absolute',
                                      top: '50%',
                                      transform:'translateY(-50%)',
                                      right: '-3rem',
                                      width: '30px',
                                      height: '30px',
                                      color: 'green'
                                    }} 
                                  />}
                                </SuiBox>
                              <ErrorMessage name={formFields[field].name} errors={errors} />
                              </SuiBox>
                            </SuiBox>
                        )
                                                
                        default:
                            break;
                      }
                  })}
                </SuiBox>
                <Divider />

                <SuiBox mt={4} textAlign="center">
                  <SuiBox display="flex" justifyContent="center">
                    <SuiBox mr={1.5}>
                      <SuiButton component={Button} type="submit" color="dark" variant="gradient">
                        Submit Request
                      </SuiButton>
                    </SuiBox>
                    <SuiButton component={Button} color="dark" variant="outlined" onClick={handleCloseRequestor}>
                      Cancel
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </form>
        </FormProvider>
      </>
      }
    </RequestorRoot>
    </>
  );
}

export default Requestor;
