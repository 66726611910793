import axios from 'axios'

class DashboardProvider {
    static async GetDashboardData(UserPrincipalName) {
        let results = []
        await axios.get(process.env.REACT_APP_DOTNET_AWS_API + `/Dashboard/GetDashboardData?UserPrincipalName=${UserPrincipalName}`)
            .then(res => {
                if (res.data.length > 0) {
                    results = res.data
                }
            })
            .catch(err => {
                console.log(err)
            })
        return results;
    }

    static async PostLastLoginDate(UserPrincipalName) {
        let results = []
        await axios.post(process.env.REACT_APP_AWS_API + `/LoginTrack?UserPrincipalName=${UserPrincipalName}`)
            .then(res => {
                if (res.data.length > 0) {
                    results = res.data
                }
            })
            .catch(err => {
                console.log(err)
            })
        return results;
    }

}

export default DashboardProvider;