/* eslint-disable */
const number = new RegExp(/^[0-9]*$/);
const age = new RegExp(/^(1[89]|[2-9]\d)$/);
const phone = new RegExp(/^((0)[1-8][0-9]{8}) *$/);
const mobile = new RegExp(/^((0)[6-8][0-9]{8}) *$/);
const alphaAndNumeric = new RegExp(/[a-zA-Z-\s]+\d*$/);
const alphaAndNumericWithSpaces = new RegExp(/^[\.a-zA-Z0-9,_!? ]*$/);
const alpha = new RegExp(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*) *$/);
const currency = new RegExp(/^(\$?\d{1,3}(?:,?\d{3})*(?:\.\d{2})?|\.\d{2})?$/);
const date = new RegExp(/^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-\d{4}$/);
const alphaWithSpaces = new RegExp(/^[a-zA-Z\s]+(([',. -][a-zA-Z ])?[a-zA-Z]*) *$/);
const email = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-_\.\+]+@([a-zA-Z]|[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9])\.[a-zA-Z0-9]{2,10}(?:\.[a-zA-Z]{2,10})?$/);

/* Property Format Checks */
const username = new RegExp("^Rewardsco\\\\[A-Za-z0-9.]+$");
const rewardscoEmail = new RegExp("^[A-Za-z0-9.]+@(rewardsco\.co\.za|rewards\.co\.za)$");
const rewardscoAgentEmail = new RegExp("^[A-Za-z0-9.]+@(rewardsco\.co\.za|rewards\.co\.za)$");

export {
  age,
  date,
  phone,
  alpha,
  email,
  mobile,
  number,
  currency,
  alphaAndNumeric,
  alphaWithSpaces,
  alphaAndNumericWithSpaces,
  username,
  rewardscoEmail,
  rewardscoAgentEmail
};
