/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";



// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import { memo } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";

function UsersList({ title, profiles, groupedProfiles, className, removeUserFromList }) {
  //console.log('profiles rerender...')
  //console.log('groupedProfiles:', groupedProfiles);
  const renderProfiles = profiles && Array.isArray(profiles) && profiles.map(({ image, fullName, employeeNumber, role, action, style, className }) => (
    <SuiBox className={className || ''} style={style || {}} key={fullName} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <SuiBox mr={2}>
        <SuiAvatar src={image} alt="something here" variant="rounded" shadow="md" fullName={fullName} />
      </SuiBox>
      <SuiBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <SuiTypography variant="button" fontWeight="medium">
          {fullName}
        </SuiTypography>
        <SuiTypography variant="caption" color="text">
          {employeeNumber}
        </SuiTypography>
      </SuiBox>
      <SuiBox ml="auto">
          {removeUserFromList && typeof removeUserFromList === "function" && <SuiButton onClick={() => removeUserFromList(employeeNumber)} component={Button} variant="text" color="info">
            x
          </SuiButton>}
      </SuiBox>
    </SuiBox>
  ));

  const renderGroupedProfiles = groupedProfiles && Array.isArray(groupedProfiles) && groupedProfiles.map((groupedArray, index) => (
    <SuiBox mb={3}  key={index} sx={{borderLeft:"#a8e7f6 solid 5px"}} my={4} px={2}>
      {/* MANAGER AND CAMPAIGNS */}
      <SuiTypography variant="h6">
        {groupedArray[0].JuniorManager}  <SuiTypography color="info" variant="caption" fontWeight="medium">(Junior Manager assigned)</SuiTypography>
      </SuiTypography>
      <SuiTypography variant="overline" fontWeight="medium"> 
        {groupedArray[0].Campaigns.map((item, i) => i + 1 !== groupedArray[0].Campaigns.length ? item.CampaignCode + ', ': item.CampaignCode)} <SuiTypography color="info" variant="caption" fontWeight="medium">(Campaign/s assigned)</SuiTypography>
      </SuiTypography>
      <br />
      <SuiTypography variant="caption" fontWeight="medium">Agents Affected:</SuiTypography>
      {groupedArray && groupedArray.length > 0 && groupedArray.map((user) => (
        <SuiBox className={'user-microcard'} key={user.EmployeeCode} component="li" display="flex" alignItems="center" py={1} mb={1}>
          <SuiBox mr={2}>
            <SuiAvatar src={Image} alt="something here" variant="rounded" shadow="md" />
          </SuiBox>
          <SuiBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
            <SuiTypography variant="button" fontWeight="medium">
              {user.EmployeeName}
            </SuiTypography>
            <SuiTypography variant="caption" color="text">
              {user.EmployeeCode}
            </SuiTypography>
          </SuiBox>
          <SuiBox ml="auto">
              {removeUserFromList && typeof removeUserFromList === "function" && <SuiButton onClick={() => removeUserFromList(user.EmployeeCode)} component={Button} variant="text" color="info">
                x
              </SuiButton>}
          </SuiBox>
        </SuiBox>
      ))}
      <Divider />
    </SuiBox>
  ));

  

  return (
    <div style={{ height: "100%" }}>
      <SuiBox pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox className={className || ''} p={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {profiles && <>
            {renderProfiles.length > 0 ? renderProfiles : <SuiTypography display='flex' justifyContent='center' variant='h6'>No users selected...</SuiTypography>}
          </>}
          {groupedProfiles && <>
            {renderGroupedProfiles.length > 0 ? renderGroupedProfiles : <SuiTypography display='flex' justifyContent='center' variant='h6'>No users selected...</SuiTypography>}
          </>}
        </SuiBox>
      </SuiBox>
    </div>
  );
}

// Typechecking props for the UsersList
UsersList.propTypes = {
  title: PropTypes.string,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupedProfiles: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

export default memo(UsersList);
