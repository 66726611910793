import axios from 'axios'

class ApprovalProvider {
  
  static async getApprovals() {
    let results = []
     await axios.get(process.env.REACT_APP_AWS_API+'/Approvals?UserPrincipalName=' + localStorage.getItem('userPrincipalName'))
      .then(res=>{
        if (res.data.length > 0) {
            results = res.data
          }
      })
      .catch(err=>{
        console.log(err)
    })  
    return results;
  }

  static async approveOrRejected(id, requestStatus, comment) {
    let results = []
     await axios.post(process.env.REACT_APP_AWS_API+'/UpdateApproval?id=' + id + '&requestStatus=' + requestStatus + '&comment=' + comment)
      .then(res=>{
        if (res.data.length > 0) {
            results = res.data
          }
      })
      .catch(err=>{
        console.log(err)
    })  
    return results;
  }

  static async actionApprovalRequest(id) {
    let results = []
    const req = {
      id: id
    };
     await axios.post(process.env.REACT_APP_AWS_API+'/ActionApprovalRequest', req)
      .then(res=>{
        if (res.data.length > 0) {
            results = res.data
          }
      })
      .catch(err=>{
        console.log(err)
    })  
    return results;
  }

  static async disallowActions(requestType) {
    let results;
     await axios.get(process.env.REACT_APP_AWS_API+`/DisallowActions?UserPrincipalName=${localStorage.getItem('userPrincipalName')}&RequestTypeValue=${requestType}`)
      .then(res=>{
        if (res.data != null) {
            results = res.data
          }
      })
      .catch(err=>{
        console.log(err)
    })  
    return results;
  }

  static async isValidApprovers(approversNumbers) {
    let result = null;
    await axios.post(process.env.REACT_APP_AWS_API + '/IsValidApprovers', {EmployeeNumbers: approversNumbers}, 
    {headers: {
      'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY
    }})
      .then(res => {
        if (res && res.data) {
          result = res.data;
        }
      })
      .catch(err => {
        console.log(err);
      })
      return result;
  }
}

export default ApprovalProvider;