import React, { useCallback, useState, useEffect, useRef } from 'react';
import { AiOutlineCloudUpload, AiOutlineClose } from 'react-icons/ai';
import {useDropzone} from 'react-dropzone';
import Papa from "papaparse";
import { has } from 'lodash';
import SuiTypography from 'components/SuiTypography';
import { useForm } from 'react-hook-form';
import BeatLoader from "react-spinners/BeatLoader";


const UserSearch = ({ className, invalidRecords, isValid, isLoading, fileValidationCheck, handleNextStep}) => {
  const { handleSubmit } = useForm();
  const [files, setFiles] = useState([]);
  const [filesMessage, setFilesMessage] = useState({});
  const csvToJsonObject = useRef([]);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setFilesMessage({});
      setFiles(acceptedFiles);
    }else{
      setFilesMessage({type: 'error', description: 'Incorrect filetype (Required: .csv)'});
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: {'text/csv': ['.csv']}, minSize: 0, maxSize: 2*1000*1000 });

  function removeObjectsWithEmptyProperties(arr) {
    let result = arr;
    for (let i = result.length - 1; i >= 0; i--) {
        if (allPropertiesEmpty(arr[i])) {
          result.splice(i, 1);
        }
    }
    return result;
  }

  function allPropertiesEmpty(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== '') {
            return false;
        }
    }
    return true;
  }

  const readUploadFile = (files) => {
    if (files) {
      Papa.parse(files[0], {
        delimiter: ';',
        header: true,
        complete: function(results) {         
          csvToJsonObject.current = removeObjectsWithEmptyProperties(results.data);
          console.log('File Upload:', csvToJsonObject.current);
          fileValidationCheck(csvToJsonObject.current);
        }}
      )
    }
  }

  const onSubmit = () => {
    if(invalidRecords.length === 0 && files.length === 1){
      handleNextStep(csvToJsonObject.current);
    }
  }
  
  useEffect(() => {
    if(files.length > 0){
      readUploadFile(files);
    }
  }, [files])

  useEffect(() => {
    if(isValid && files && files[0]){
    setFilesMessage({type: 'success', description: files[0].name})
    }
  }, [isValid])
 
 
  return (
    <div className='file-upload-block'>
      <form onSubmit={handleSubmit(onSubmit)} id="modal-form">
        <div {...getRootProps({className: className})} className='drag-drop-window'>
          <input {...getInputProps({accept: ['csv']})} />
          <div className='content'>
            <AiOutlineCloudUpload className='icon' />
          { isDragActive ? (
            <p>Drag and drop a file, or browse</p>
          ) : (
            <>
              <p>Drag and drop files, or click to browse files</p>
              <SuiTypography fontWeight="bold" color={invalidRecords.length > 0 ? 'error' : filesMessage.type} variant="overline">{filesMessage.description}</SuiTypography>
            </>
          )}
          {isLoading && <BeatLoader color={'#D0021B'} size={10} margin={2} />}

        </div>
      </div>
      </form>
    </div>
  );
}

export default UserSearch