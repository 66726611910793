/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SuiAvatar
import SuiAvatarRoot from "components/SuiAvatar/SuiAvatarRoot";

const bgColors = ['primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark'];

// To return random background color for SuiAvatar 
function getBgColor(colors) {
  return colors[Math.floor(Math.random() * colors.length)];
}

const SuiAvatar = forwardRef(({ bgColor, size, shadow, fullName, profiles, ...rest }, ref) => (
  <SuiAvatarRoot ref={ref} ownerState={{ shadow, bgColor:fullName?getBgColor(bgColors):bgColor, size , fullName ,profiles }} {...rest} >
   {fullName && <div className="MuiAvatar-root MuiAvatar-circular sc-gsTCUz eFPKoE ">{fullName.charAt(0)}</div>}
  </SuiAvatarRoot>
));

// Setting default values for the props of SuiAvatar
SuiAvatar.defaultProps = {
  bgColor: "transparent",
  size: "md",
  shadow: "none",
};

// Typechecking props for the SuiAvatar
SuiAvatar.propTypes = {
  bgColor: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  shadow: PropTypes.oneOf(["none", "xs", "sm", "md", "lg", "xl", "xxl", "inset"]),
};

export default SuiAvatar;
