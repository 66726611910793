import React, { useState, useEffect } from 'react';
import Dashboard from "layouts/dashboard";
import AppCenter from "layouts/app-center";
import Profile from "layouts/profile";
import Notifications from "layouts/notifications";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import NotificationsIcon from '@mui/icons-material/Notifications';
import GroupsIcon from '@mui/icons-material/Groups';
import Approvals from "layouts/approvals";
import ApprovalIcon from '@mui/icons-material/Approval';
import TeamMembers from "layouts/team-members";
import TeamManagement from "layouts/team-management";
import Routing from "./static/configs/routingRoleConfig.json"
import { number } from './helpers/regex';

const componentMap = {
  "Dashboard": Dashboard,
  "TeamManagement": TeamManagement,
  "Notifications": Notifications,
  "Profile": Profile,
  "AppCenter": AppCenter,
  "Approvals": Approvals,
  "TeamMembers": TeamMembers,
};

const iconMap = {
  "Shop": <Shop size="12px" />,
  "Office": <Office size="12px" />,
  "Document": <Document size="12px" />,
  "SpaceShip": <SpaceShip size="12px" />,
  "CustomerSupport": <CustomerSupport size="12px" />,
  "NotificationsIcon": <NotificationsIcon size="12px" />,
  "GroupsIcon": <GroupsIcon size="12px" />,
  "ApprovalIcon": <ApprovalIcon size="12px" />,
};

const routes = () => {
   
  let currentRoleId = localStorage.getItem('roleId') || "1";

  if(!number.test(currentRoleId) || parseInt(currentRoleId) > 10){
    currentRoleId = "1";
    localStorage.setItem("roleId", "1");
  }
  
  let roleConfig = Routing.find(obj => {
    return obj.role_id.toString() === currentRoleId;
  })

  let tempRoutes = roleConfig.settings.menu_items.map((item) => ({
    ...item,
    "icon": renderIcon(item.icon),
    "component": renderComponent(item.component)
  }))

  return tempRoutes

}

function renderComponent(componentReference) {
  const component = componentMap[componentReference];

  if (component) {
    return component;
  } else {
    return null;
  }
}

function renderIcon(iconReference) {
  const component = iconMap[iconReference];
  if (component) {
    return component;
  } else {
    
    return null;
  }
}

export default routes;
