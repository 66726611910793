import React, { useEffect, useState, useRef, useCallback } from "react";
import SuiInput from 'components/SuiInput'
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import UsersList from 'examples/Lists/UsersList';
import '../../styles/components/User.scss'
import { isEmpty } from "lodash";
import Divider from "@mui/material/Divider";
import SuiButton from "components/SuiButton";
import UsersProvider from "../../providers/UsersProvider.js";
import noResults from "../../assets/images/no-results.webp";
import BeatLoader from "react-spinners/BeatLoader";
import { useFormContext, useForm } from 'react-hook-form';


const UserSearch = (props) => {
  const { getValues } = useFormContext();
  const { handleSubmit, register } = useForm();
  const {handleNextStep, searchType, users, userMoveRoleType, multiMove} = props;
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const searchFieldRef = useRef(null);
  const [searchResultsMessage, setSearchResultsMessage] = useState('');
  const [searchField, setSearchField] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [userList, setUserList] = useState([]);
  const [searchError, setSearchError] = useState('');

  const organization = {
    Agent: {
      higherUp: "Junior Manager"
    },
    "Junior Manager": {
      higherUp: "General Manager"
    },
    "General Manager": {
      higherUp: "Head of Sales"
    },
    "Head of Sales": {
      higherUp: null  // Assuming the Head of Sales is the highest level
    }
  };
  
  const onSuggestionSelect = (user) => {
    

    let tempObj = {
      image: "",
      fullName: user.fullName,
      employeeNumber: user.employeeNumber,
      userPrincipalName: user.userPrincipalName,
      role: user.role,
      className:'user-microcard',
      action: {
        color: "info",
        label: "x",
      },
    }
    addUserToList(tempObj);
    setSearchField('');
    searchFieldRef.current.focus();
  }

  const addUserToList = (user) => {
    //DO LOGIC TO IMPLEMENT ONLY ONE MOVING MANAGER AT A TIME
    
    if(searchType === 'agent' && userList.length >= 1 && (multiMove === false || getValues("MovementRole").includes("Manager"))){
      setSearchError('Can only select 1 User.');
      setShowSuggestions(false);

      return;
    }
    if(searchType === 'manager' && userList.length >= 1 && multiMove === false){
      setSearchError('Can only select 1 Manager to be assigned users.');
      setShowSuggestions(false);

      return;
    }

    let tempArray = userList;
    const checkUsername = item => item.employeeNumber === user.employeeNumber;
    !tempArray.some(checkUsername) ? tempArray = [user, ...tempArray] : setSearchError('User already exists in the list below');
    setUserList(tempArray);
    setShowSuggestions(false);
  }

  const removeUserFromList = (employeeNumber) => {
    let tempArray = userList;
    let filteredArray = tempArray.filter((item) => {
       return item.employeeNumber !== employeeNumber;
    });
    
    setUserList(filteredArray);
  }

  const updateSearchField = (value) => {
    setSearchField(value);
    setShowSuggestions(false);
    value.length >= 3 ? setIsDisabled(false) : setIsDisabled(true);
  }

  const getSearchResults = () => {
    switch (searchType) {
      case 'agent':
        if(users.length > 0){
          let tempArray = users;
          let filteredArray = tempArray.filter((member) => {
            return member.EmployeeNumber.includes(searchField);
          });
          
          if(filteredArray.length > 0){
            setSearchResults(filteredArray);
            setShowSuggestions(true);
          }else{
            setSearchResultsMessage('No results found...')
            setSearchResults([]);
            setShowSuggestions(true);
          }
        }

        break;
      case 'global-search':
        setShowSuggestions(true);
        setIsSearching(true);
        setSearchResults([]);


        try {
          const getUser = async() => {
            const res = await UsersProvider.getUserByEmployeeNumber(searchField);

            res && res.Role !== null && res.Role === userMoveRoleType ? setSearchResults([res]) : 
            setSearchResultsMessage('No results found...');
            setIsSearching(false);
            setIsLoading(false);
          }
          getUser();

        } catch (error) {
          //DO NOTHING FOR NOW
        }

        break;
      case 'manager':
        if(!users){
          setShowSuggestions(true);
          setIsSearching(true);
          setSearchResults([]);


          try {
            const getManagers = async() => {
              const res = await UsersProvider.getUserByEmployeeNumber(searchField);

              res && res.Role !== null && res.Role.includes(organization[userMoveRoleType].higherUp) ? setSearchResults([res]) : 
              setSearchResultsMessage('No results found...');
              setIsSearching(false);
              setIsLoading(false);
            }
            getManagers();

          } catch (error) {
            //DO NOTHING FOR NOW
          }
        }
        
        break;
    
      default:
        break;
    }
  }

  const onSubmit = () => {
    handleNextStep(userList);
  }

  const memoizedRemoveUserFromList = useCallback(removeUserFromList, [userList]);

    
  useEffect(() => {
    if(!isEmpty(searchField)){
       ('');
    }
  }, [searchField])

  useEffect(() => {
    if(searchType === 'agent' && users.length === 0){
      setIsLoading(true);
    }else{
      setIsLoading(false);
    }
  }, [users])

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="modal-form" className='searchBy-empNumber-block'>
      <SuiBox className='search-container'>
        <div className="search-bar">
          <SuiInput 
            disabled={isLoading}
            placeholder={`Find ${searchType || 'user'} by employee number` }
            {...register("search-user", {pattern: /^[a-zA-Z0-9]+$/})} 
            title="Please enter numbers or letters"
            onChange={(e) => {updateSearchField(e.target.value);}}
            inputRef={searchFieldRef}
            value={searchField}
            onKeyPress={(e) => {
              if(e.key === 'Enter'){
                e.preventDefault(); 
                getSearchResults();
              }
            }}
          />
          <SuiButton disabled={isDisabled} onClick={() => getSearchResults()} color={'info'}>Search</SuiButton>
        </div>
        {searchError && <SuiTypography padding={2} color="error" variant="overline">{searchError}</SuiTypography>}
        {showSuggestions && <div className='search-results'>
            { searchResults.length > 0 && <><div className="headings">
                <p>Name</p>
                <p>Employee number</p>
              </div>
            <Divider /></>}
          {isSearching && <SuiTypography padding={2} variant='caption'>Searching for employees...</SuiTypography>}
          {!isSearching && searchResults.length === 0 && searchResultsMessage && 
          <div className="no-result">
              <div>
                <img src={noResults} alt="no-results"/>     
                <SuiTypography variant="h6">{searchResultsMessage}</SuiTypography>
                <SuiTypography variant="caption"> This search query is applied to your current team members under you.</SuiTypography>
              </div>
          </div>}
          {!isSearching && searchResults.length > 0 && searchResults.map((user) => (
            <div key={user.EmployeeNumber} className="table-row" onClick={() => onSuggestionSelect({fullName: user.FullName, employeeNumber: user.EmployeeNumber, userPrincipalName: user.UserPrincipalName})}>
              <p>{user.FullName}</p>
              <p>{user.EmployeeNumber}</p>
          </div>
          ))}
        </div>}
      </SuiBox>

      {isLoading && <SuiBox display="flex" flexDirection="column" alignItems="center" justifyContent="center" my={5}>
        <BeatLoader color={'#D0021B'} size={15} />
        <SuiTypography variant="caption">Fetching Team Members</SuiTypography>
      </SuiBox>}
         
      <SuiBox>

      {!isLoading && userList && <UsersList
        className="list"
        profiles={userList}
        removeUserFromList={memoizedRemoveUserFromList}
      />}
      </SuiBox>
    </form>
    
  )
}

export default UserSearch