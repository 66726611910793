import React, { useEffect, useState, useRef } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import { CSVLink } from 'react-csv';
import SuiTypography from "components/SuiTypography";
import FileUpload from "../../User/FileUpload.jsx";
import { AiOutlineClose } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import Card from "@mui/material/Card";
import CampaignsProvider from "../../../providers/CampaignProvider.js";
import UserConfirmationList from "../../User/UserConfirmationList";
import UserRoleSelect from "../../../components/User/UserRoleSelect";
import ProfileProvider from "../../../providers/ProfileProvider";
import UsersProvider from "../../../providers/UsersProvider";
import { ImDownload2 } from 'react-icons/im';
import templates from '../../../static/templates/fileUpload.json'
import Icon from "@mui/material/Icon";
import { useFormContext, useForm } from 'react-hook-form';
import { 
  username, 
  alphaAndNumericWithSpaces, 
  rewardscoEmail, 
  rewardscoAgentEmail, 
  alphaWithSpaces, 
  mobile, 
  number } from "../../../helpers/regex";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useSoftUIController, setRequestor } from "context";
import { isEmpty } from "lodash";
import { has, uniq } from 'lodash';


const UserMovementModal = ({view, setCurrentModal, categoryType, requestType}) => {
  const { getValues, setValue } = useFormContext();
  const { handleSubmit, register } = useForm();
  const userType = getValues('MovementRole');
  const approverFileTemplate = templates.userOnboard[userType] || null;
  const Fields = [
    {
      name: 'UserName',
      regex: username,
      required: true
    },
    {
      name: 'Name',
      regex: alphaWithSpaces,
      required: true
    },
    {
      name: 'Surname',
      regex: alphaWithSpaces,
      required: true
    },
    {
      name: 'UserPrincipalName',
      regex: userType === 'Agent' ? rewardscoAgentEmail : rewardscoEmail,
      required: true
    },
    {
      name: 'MobileNumber',
      regex: mobile,
      required: false
    },
    {
      name: 'EmployeeNumber',
      regex: number,
      required: true
    },
    {
      name: 'Role',
      options: ['Agent', 'Junior Manager', 'General Manager'],
      ruleMatch: userType,
      required: true
    },
    {
      name: 'ManagerUserPrincipalName',
      regex: rewardscoEmail,
      required: true
    },
    {
      name: 'ManagerEmployeeNumber',
      regex: number,
      required: true
    },
    {
      name: 'CampaignCodes',
      regex: alphaAndNumericWithSpaces,
      required: false
    },
  ];

  const [controller, dispatch] = useSoftUIController();
  const { requestor } = controller;
  const [title, setTitle] = useState('');
  const [stepLimit, setStepLimit] = useState(0);
  const [step, setStep] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidRecords, setInvalidRecords] = useState([]);
  const [showInvalidRecords, setShowInvalidRecords] = useState(false);
  const [existingCampaigns, setExistingCampaigns] = useState([]);
  // const [requestorObject, setRequestorObject] = useState({});
  const [requestorObject, setRequestorObject] = useState({});
  let managerProfile = useRef();

  const handleStep = (direction) => {
    if (step === stepLimit && direction === '+') {
      setCurrentModal({});
      return;
    }

    if(direction === '+'){
      setStep(step + 1);
    }
    else if(direction === '-'){
      if(step === 0) return;
      setStep(step - 1);
    }
  };

  const handleNextStep = async () => {
    if(view === 'file-upload'){
      switch (step) {
        case 0:
          setMovementRole();
          break;

        case 1:
          setFileUpload();
          break;
  
        case 2:
          saveToContext();
          break;
    
        default:
          setCurrentModal({});
          break;
      }
    }
	};

  const saveToContext = () => {
    if(view === 'file-upload'){
      setValue("initiateAdd", requestorObject);
      setRequestor(dispatch, requestorObject);
      setCurrentModal({});
    }
  }

  const setMovementRole = () => {
    if(!isEmpty(getValues("MovementRole"))){
      handleStep('+');
    }
  }

  const setFileUpload = () => {
    if(invalidRecords.length === 0){
      handleStep('+');
    }
  }

  function compareArrays(array1, array2) {
    let invalidations = [];
    for (let value of array1) {
        if (!array2.includes(value)) {
            invalidations.push({description: `Campaign ${value} does not exist.`});
        }
    }
    return invalidations;
}

  const fieldValidation = async(objects) => {
    let invalidations = [];
    let campaigns = [];
    let managers = [];
    let managerEmpNumbers = [];
    let userEmpNumbers = [];
    let userNames = [];

    setIsLoading(true);

    for (let i = 0; i < objects.length; i++) {      
      const object = objects[i];
      userEmpNumbers.push(object['EmployeeNumber']);

      for (let j = 0; j < Fields.length; j++) {
        const { name, regex, options, ruleMatch, required } = Fields[j];
        if (!object.hasOwnProperty(name)) {
            invalidations.push({description: `Record/Row ${i + 2} => Field ${name} missing.`});
            return invalidations;
        }

        if(isEmpty(object[name]) && required == false){
          continue;
        }

        if (isEmpty(object[name]) || (regex && !regex.test(object[name]))) {
            invalidations.push({description: `Record/Row ${i + 2} => Field ${name} does meet the format requirements.`});
        }

        if(options && !options.includes(object[name])){
          invalidations.push({description: `Record/Row ${i + 2} => Field ${name} must be one the following: ${JSON.stringify(options)}.`});
        }

        if(ruleMatch && ruleMatch !== object[name]){
          invalidations.push({description: `Record/Row ${i + 2} => User ${name} must be ${userType} for an ${userType} Onboard Request.`});
        }

        if(name === 'UserName'){
          const usernameResult = validUsername(object);
          !usernameResult ? 
          invalidations.push({description: `Record/Row ${i + 2} => UserName format invalid for ${userType} user.`}) : null;

          if (!userNames.includes(object[name])) {
            userNames.push(object[name]);
          }else{
            invalidations.push({description: `Record/Row ${i + 2} => Duplicate UserName Record.`})
          }
        }

        if(name === 'UserPrincipalName'){
          const emailResult = validEmail(object);
          !emailResult ? 
          invalidations.push({description: `Record/Row ${i + 2} => Email format invalid for ${userType} user`}) : null;
        }

        if(name === 'CampaignCodes'){
          const campaignsArray = object[name].split(',');
          object[name] = campaignsArray;
          for (let index = 0; index < campaignsArray.length; index++) {
            const campaignCode = campaignsArray[index];
            
            //Check Campaign Actually exists
            if (!campaigns.includes(campaignCode)) {
              campaigns.push(campaignCode);
            }
          }
        }

        if(name === 'ManagerUserPrincipalName'){
          if (!managers.includes(object[name])) {
            managers.push(object[name]);
          }
        }

        if(name === 'ManagerEmployeeNumber'){
          if (!managerEmpNumbers.includes(object[name])) {
            managerEmpNumbers.push(object[name]);
          }
        }
      }
    }

    if(userEmpNumbers.length > 0){
      uniq(userEmpNumbers).length !== userEmpNumbers.length ?
      invalidations.push({description: `Column EmployeeNumber => Duplicate Employee Numbers`}) : null;
    }

    if(invalidations && invalidations.length > 0){ setIsLoading(false); return invalidations };

    //Validate Manager has those campaigns underneath him/her
    if(managers.length > 1){
      invalidations.push({description: `Column ManagerUserPrincipalName => Only 1 manager can be assigned per Onboard request.`});
    }

    if(managerEmpNumbers.length > 1){
      invalidations.push({description: `Column ManagerEmployeeNumber => Only 1 manager employee number can be assigned per Onboard request.`});
    }else{

      try {
        let managerRole = '';
        managerProfile.current = await UsersProvider.getUserByEmployeeNumber(objects[0].ManagerEmployeeNumber);

        if(managerProfile.current){
          managerRole = managerProfile.current.Role;

          objects[0].ManagerUserPrincipalName.toLowerCase() !== managerProfile.current.UserPrincipalName.toLowerCase() ?
          invalidations.push({description: `ManagerUserPrincipalName and ManagerEmployeeNumber do not match the same user.`}) : null;
  
          objects[0].ManagerEmployeeNumber !== managerProfile.current.EmployeeNumber ?
          invalidations.push({description: `ManagerUserPrincipalName and ManagerEmployeeNumber do not match the same user.`}) : null;
  
          userType === 'Agent' && managerRole !== 'Junior Manager' ? 
          invalidations.push({description: `Can't assign ${userType} users to ${managerRole}`}) :
          userType === 'Junior Manager' && managerRole !== 'General Manager' ? 
          invalidations.push({description: `Can't assign ${userType} users to ${managerRole}`}) :
          userType === 'General Manager' && managerRole !== 'Head of Sales' ? 
          invalidations.push({description: `Can't assign ${userType} users to ${managerRole}`}) : null;
        }else{
          invalidations.push({description: `Invalid ManagerEmployeeNumber`});
        }

      } catch (error) {
        invalidations.push({description: `Column ManagerEmployeeNumber failed validating manager information. Please retry upload file.`});
      }
    }

    if(invalidations && invalidations.length > 0){ setIsLoading(false); return invalidations };
    
    //Check if campaigns exist then => Check if Manager has that campaign under them
    if(campaigns && campaigns.length > 0){
      let dbCampaignCodes = [];
      const dbCampaigns = await getCampaigns();

      for (let i = 0; i < dbCampaigns.length; i++) {
        dbCampaignCodes.push(dbCampaigns[i].Code);
      }

      let campaignInvalidations = compareArrays(campaigns, dbCampaignCodes);
      invalidations = [...invalidations, ...campaignInvalidations];
    }

    const managerCampaigns = managerProfile.current.Campaigns.map(camp => camp.Code);
    if(managerCampaigns.length > 0){
      for (let value of campaigns) {
        if (!managerCampaigns.includes(value)) {
            invalidations.push({description: `Campaign ${value} is not assigned to that manager.`});
        }
      }
    }

    setIsLoading(false);
    return invalidations; // All objects contain all required properties with valid values
  }

  const fileValidationCheck = async(data) => {
    let invalidations = [];
    setInvalidRecords([]);
    setIsValid(false);

    
    //Validate Required Headers
    for (let field of Fields) {
      if (!has(data[0], field.name)) {
        invalidations.push({description: `Invalid file. Missing required header ${field.name}`});
      }
    }

    if(invalidations && invalidations.length > 0){ setInvalidRecords(invalidations); return; }

    // 2) Validate Record Format
    const invalidationsResp = await fieldValidation(data);
    if(invalidationsResp.length > 0){ setInvalidRecords(invalidationsResp); return; }

    setIsValid(true);
    setRequestorObject({'initiateAdd': data});
  }

  const validUsername = (input) => {
    let username = '';

    if(userType === 'Agent'){
      username = 'Rewardsco\\' + (input?.Name && input?.Name[0] ) + 
                 (input?.Surname && input?.Surname.substring(0, 3).toLowerCase()) + 
                 (input?.EmployeeNumber && input?.EmployeeNumber);

    }else{
      username = 'Rewardsco\\' + (input?.Name) + '.' + (input?.Surname);
    }

    return input.UserName === username; 
  }

  const validEmail = (input) => {
    let username = '';
    let email = '';

    if(userType === 'Agent'){
      username = (input?.Name && input?.Name[0] ) + 
                 (input?.Surname && input?.Surname.substring(0, 3).toLowerCase()) + 
                 (input?.EmployeeNumber && input?.EmployeeNumber);
                 
      email = username + '@rewardsco.co.za';
      return email === input.UserPrincipalName; 

    }else{

      username = (input?.Name) + '.' + (input?.Surname);
      return (username + '@rewardsco.co.za' === input.UserPrincipalName) || (username + '@rewards.co.za' === input.UserPrincipalName); 

    }
  }

  const getCampaigns = async() => {
    if(existingCampaigns.length > 0) return existingCampaigns;

    let campaigns = null;
    setIsSearching(true);
    try {
      campaigns = await CampaignsProvider.getCampaigns();
      if(campaigns.length > 0){
        setExistingCampaigns(res);
      }
    } catch (error) {
      //DO NOTHING FOR NOW
    }
    setIsSearching(false);
    return campaigns;     
  }

  const getUserCampaigns = async(email) => {
    setIsSearching(true);
    try {
      let campaignsCodes  = [];
      const res = await CampaignsProvider.getUserCampaigns(email);

      if(res.length > 0){
        for (let index = 0; index < res.length; index++) {
          const campaign = res[index];
          campaignsCodes.push(campaign?.Code);
        }
      }
      return campaignsCodes;

    } catch (error) {
      //DO NOTHING FOR NOW
    }
  }

  useEffect(() => {
    const getData = async() => {
      if(view === 'file-upload'){
        setStepLimit(2);
        switch (step) {

          case 0:
            setTitle('Which user role do you wish to move?');
            break;

          case 1:
            setTitle('Who would you like to add?');
            break;
  
          case 2:
            setTitle('Adding Confirmation:');
            break;
        
          default:
            setCurrentModal({});
            break;
        }
      }
    }
    getData();
  }, [step])

  return (
    <div>
      {step !== 0 && <SuiTypography 
          onClick={() => {
            handleStep('-');
          }}
          my={2} 
          display="flex" gap={1} 
          alignItems="center" 
          color="text" 
          fontWeight="medium" 
          variant="button"> 
            <IoChevronBackCircleOutline /> Back
      </SuiTypography>}
      <SuiBox mb={1} alignItems="baseline" justifyContent="space-between" display="flex" className="header">
        <SuiTypography pb={2} variant="h6">{title}</SuiTypography>
        {approverFileTemplate && step === 1 && <CSVLink separator=";" filename={`${userType}-onboard-template.csv`} data={approverFileTemplate}>
        <SuiButton color="info"  >
          <ImDownload2 /> &nbsp; Download Template
        </SuiButton>
        </CSVLink>}
      </SuiBox>

      <SuiBox>
        <div className="form-group">
          <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
            {view === "file-upload" && (
              <>
                {step === 0 && (
                  <UserRoleSelect
                    handleNextStep={handleNextStep}
                    setIsValid={setIsValid}
                  />
                )}
                {step === 1 && (
                  <FileUpload
                    isLoading={isLoading}
                    isValid={isValid}
                    invalidRecords={invalidRecords}
                    fileValidationCheck={fileValidationCheck}
                    handleNextStep={handleNextStep}
                  />
                )}
                {step === 2 && (
                  <UserConfirmationList 
                    className="file-upload-block" 
                    profiles={requestorObject?.initiateAdd.map((user) => ({
                      fullName: user.Name + ' ' + user.Surname,
                      employeeNumber: user.EmployeeNumber
                    }))}
                    handleNextStep={handleNextStep}
                  />
                )}
              </>
            )}
            {/* {view === "search-agent" && (
              <>
                {step === 0 && (
                  <UserRoleSelect
                    handleNextStep={handleNextStep}
                  />
                )}
                {step === 1 && (
                  <SuiBox mb={3}>
                    <form onSubmit={handleSubmit(handleNextStep)} id="modal-form">
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} xl={6}>
                          <SuiTypography pr={2} fontWeight="bold" variant="caption">UserName:</SuiTypography>
                          <SuiTypography fontWeight="normal" variant="caption">{renderUsername()}</SuiTypography>
                        </Grid>
                        <Grid gap={2} item xs={12} sm={6} xl={6}>
                          <SuiTypography pr={2} fontWeight="bold" variant="caption">UserPrincipalName:</SuiTypography>
                          <SuiTypography fontWeight="normal" variant="caption">{userDetails?.Name || 'name'}.{userDetails?.Surname || 'surname'}@rewardsco.co.za</SuiTypography>
                        </Grid>

                        <Grid item xs={12} sm={6} xl={6}>
                          <SuiInput 
                            {...register("ia-name", {required: 'This is required', maxLength:25, pattern: alpha})} 
                            onChange={(e) => updateUserDetails('Name', e.target.value)} type="text" 
                            placeholder="Name" 
                            size="medium" 
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={6}>
                          <SuiInput 
                            {...register("ia-surname", {required: 'This is required', maxLength:25, pattern: alpha})} 
                            onChange={(e) => updateUserDetails('Surname', e.target.value)} 
                            type="text" 
                            placeholder="Surname" 
                            size="medium" 
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={6}>
                          <SuiInput 
                            {...register("ia-employeeNumber", {required: 'This is required', pattern: number})} 
                            onChange={(e) => updateUserDetails('EmployeeNumber', e.target.value)} 
                            type="text" 
                            placeholder="Employee Number" 
                            size="medium"
                            title="Eg: 1145"
                            inputProps={{maxLength:"5" }}
                          />
                          {errors['ia-name'] && <p>{errors['ia-name'].message}</p>}
                        </Grid>
                        <Grid item xs={12} sm={6} xl={6}>
                          <SuiInput 
                            {...register("ia-mobileNumber", {required: true, pattern: number})} 
                            onChange={(e) => updateUserDetails('MobileNumber', e.target.value)} 
                            placeholder="Mobile Number" 
                            size="medium" 
                            inputProps={{maxLength:"10" }}
                          />
                        </Grid>
                      </Grid>
                    </form>
                </SuiBox>
                )}
                {step === 2 && (
                  <UserSearchByEmpNumber
                    searchType="manager"
                    multiMove={false}
                    isSearching={isSearching}
                    handleNextStep={handleNextStep}
                    userMoveRoleType={getValues("MovementRole")}
                  />
                )}
                {step === 3 && (
                  <CampaignSearchByName
                    searchType="campaign"
                    isSearching={isSearching}
                    campaigns={managerCampaigns}
                    handleNextStep={handleNextStep}

                  />
                )}
              </>
            )} */}
          </SuiBox>
        </div>
      </SuiBox>

      <div className="button-section">
        {invalidRecords.length > 0 && <SuiBox className="error-tag" onClick={() => setShowInvalidRecords(true)}>
          <BiError />
          <SuiTypography color="error" variant="caption">Validation Errors Found</SuiTypography>
        </SuiBox>}
        <SuiTypography
          onClick={() => {
            setCurrentModal({});
          }}
          className="cancel-btn"
        >
          Cancel
        </SuiTypography>
        {/* <SuiButton
          form="modal-form"
          className="action-btn"
        >
          Continue
        </SuiButton> */}
        <button disabled={step === 1 && !isValid} form="modal-form" className="btn btn-orange standard">
          Continue
				</button>
      </div>
      {showInvalidRecords && 
      <Card className="file-error-card">
        <SuiBox className='header'>
          <SuiTypography variant="h6">Validation Errors</SuiTypography>
          <AiOutlineClose className="close-btn" onClick={() => {setShowInvalidRecords(false)}}/>
        </SuiBox>
        <SuiBox className="error-content" sx={{ maxHeight: '200px', minWidth: '400px' }} >
          {invalidRecords.length > 0 && invalidRecords.map((record, index) => (
            <SuiTypography key={index} color="error" variant="overline">{record.description}</SuiTypography>
          ))}
        </SuiBox>
      </Card>}
    </div>
  );
}

export default UserMovementModal