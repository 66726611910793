import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import SuiButton from "components/SuiButton";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

import UsersList from '../../../examples/Lists/UsersList';
import UserSearchByFileUpload from "../../User/UserByFileUpload.jsx";
import UserSearchByEmpNumber from "../../User/UserByEmpNumber.jsx";
import CampaignSearchByName from "../../Campaign/CampaignSearchByName.jsx";
import requesterRoleConfig  from '../../../static/configs/requesterRoleConfig.json';
import { AiOutlineClose } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import Card from "@mui/material/Card";
import UsersProvider from "../../../providers/UsersProvider.js";
import CampaignsProvider from "../../../providers/CampaignProvider.js";
import UserConfirmationList from "../../User/UserConfirmationList";
import UserRoleSelect from "../../../components/User/UserRoleSelect"
import { useFormContext } from 'react-hook-form';
import { IoChevronBackCircleOutline } from "react-icons/io5";


import {
  useSoftUIController,
  setRequestor,
} from "context";
import { isEmpty } from "lodash";
import teamMembers from "layouts/team-management";

const UserMovementModal = ({view, setCurrentModal, categoryType, requestType}) => {
  const { getValues, setValue } = useFormContext();
  const requiredFields = ['CampaignCode', 'EmployeeName', 'EmployeeCode', 'JuniorManagerEmployeeCode', 'JuniorManager'];
  const [controller, dispatch] = useSoftUIController();
  const { requestor } = controller;

  const [title, setTitle] = useState('');
  const [stepLimit, setStepLimit] = useState(0);
  const [step, setStep] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidRecords, setInvalidRecords] = useState([]);
  const [showInvalidRecords, setShowInvalidRecords] = useState(false);

  const [currentTeamMembers, setCurrentTeamMembers] = useState([]);
  const [managerCampaigns, setManagerCampaigns] = useState([]);
  const [userPrincipalName, setUserPrincipalName] = useState(localStorage.getItem('userPrincipalName'));

  const [fileRecords, setFileRecords] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [requestorObject, setRequestorObject] = useState({});
  const roleId = localStorage.getItem("roleId");
  let usersList = []



  const handleStep = (direction) => {
    if (step === stepLimit && direction === '+') {
      setCurrentModal({});
      return;
    }

    if(direction === '+'){
      setStep(step + 1);
    }
    else if(direction === '-'){
      if(step === 0) return;
      setStep(step - 1);
    }
  };

  const handleNextStep = async (formData) => {
  if(view === 'search-agent'){
      switch (step) {
        case 0:
          setMovementRole();
          break;

        case 1:
          setUsersToMove(formData);
          break;
  
        case 2:
          setUsersNewManager(formData);
          break;
  
        case 3:
          setUserCampaigns(formData);
          saveToContext();
          break;
  
        default:
          setCurrentModal({});
          break;
      }
    }
	};

  const saveToContext = () => {
    if(view === 'search-agent'){
      // setRequestor(dispatch, requestorObject);
      setValue("initiateMove", requestorObject);
      setCurrentModal({});
    }
  }

  const setMovementRole = () => {
    if(!isEmpty(getValues("MovementRole"))){
      handleStep('+');
    }
  }

  const setUsersToMove = (users) => {
    if(users && users.length > 0){
      let structuredUsers = users;
      if(users.length > 0){
         structuredUsers = users.map((user) => ({
          EmployeeName: user.fullName,
          EmployeeNumber: user.employeeNumber
        }))
      }

      setSelectedAgents(users);
      setRequestorObject({'initiateMove': structuredUsers});
      handleStep('+');
    }
  }

  const setUsersNewManager = (managers) => {
    if(managers){
      let manager = {};

      if(managers.length > 0){
        manager = {
          ManagerName: managers[0].fullName,
          ManagerEmployeeNumber: managers[0].employeeNumber,
          UserPrincipalName: managers[0].userPrincipalName

        };
      }

      if(!isEmpty(manager)){
        setSelectedManagers([manager]);
        handleStep('+');
      }else{
        setSelectedManagers([]);
      }
    }
  }

  const setUserCampaigns = (campaigns) => {

    if(campaigns.length > 0){
      let tempCampaigns = campaigns.map((campaign) => ({
        CampaignName: campaign.Type,
        CampaignCode: campaign.Code,
        CampaignId: campaign.Id
      }))

      setSelectedCampaigns(tempCampaigns);
      let tempRequestorObject = requestorObject.initiateMove;
      tempRequestorObject = tempRequestorObject.map((user) => ({
        ...user,
        ...selectedManagers[0],
        Campaigns: tempCampaigns
      }))

      setRequestorObject({'initiateMove': tempRequestorObject});
      setRequestor(dispatch, {'initiateMove': tempRequestorObject});
    }
  }

  const buildTeam = (members) => {
    let membersList = [];

    for (let index = 0; index < members.length; index++) {
      const member = members[index];
      membersList.push(member);
  
      if (member.SubordinateUsers && member.SubordinateUsers.length > 0) {
        membersList = membersList.concat(buildTeam(member.SubordinateUsers));
        delete member.subordinates;
      }
    }
    return membersList;
  }

  const buildHierarchyObject = async() => {    
    //Recurvily get until no more suborinates
    const hierarchy = await buildTeamHierarchy(userPrincipalName);

    if(hierarchy && hierarchy.SubordinateUsers){
      const fullTeam = await buildTeam(hierarchy.SubordinateUsers);
      setCurrentTeamMembers(fullTeam);
    }else{
      setCurrentTeamMembers([]);
    }

  }

  const buildTeamHierarchy = async(UserPrincipalName) => {
    const result = await getUsers(UserPrincipalName);
    const payloadObj = result[0];   

    for (let index = 0; index < payloadObj.SubordinateUsers.length; index++) {
      let subordinate = payloadObj.SubordinateUsers[index];
      if(subordinate && !isEmpty(subordinate.SubordinateUserIds)){
        payloadObj.SubordinateUsers[index] = await buildTeamHierarchy(subordinate.UserPrincipalName);
      }
    }

    return payloadObj;
  }


  //API GET CALLS
  const getUsers = async(userPrincipalName) => {
    setIsSearching(true);
    try {
      const res = await UsersProvider.getUsersSearch({userPrincipalName});
      return res ? res : null;

    } catch (error) {
      
    }
    setIsSearching(false);
  }

  const getCampaigns = async() => {
    setIsSearching(true);
    try {
      const res = await CampaignsProvider.getUserCampaigns(selectedManagers[0].UserPrincipalName);

      if(res.length > 0){

        setManagerCampaigns(res);
      }
    } catch (error) {
      //DO NOTHING FOR NOW
    }
    setIsSearching(false);      
  }

  useEffect(() => {
    const getData = async() => {
      if(view === 'search-agent'){
        setStepLimit(3);
        switch (step) {
          case 0:
            setTitle('Which user role do you wish to move?');
            // await getUsers();
            if(currentTeamMembers.length === 0){
              buildHierarchyObject();
            }
            break;

          case 1:
            setTitle('Who would you like to move?');
            // await getUsers();
            break;

          case 2:
            setTitle('Who would be the new Manager?');
            break;
  
          case 3:
            setTitle('To which campaigns will the users be moved to?');
            await getCampaigns();
            break;
        
          default:
            setCurrentModal({});
            break;
        }
      }

    }
    getData();
  }, [step])

  return (
    <div>
        {step !== 0 && <SuiTypography 
            onClick={() => {
              handleStep('-');
            }}
            my={2} 
            display="flex" gap={1} 
            alignItems="center" 
            color="text" 
            fontWeight="medium" 
            variant="button"> 
              <IoChevronBackCircleOutline /> Back
        </SuiTypography>}
      <div className="header">
        <SuiTypography pb={2} variant="h6">{title}</SuiTypography>
      </div>

      <SuiBox>
        <div className="form-group">
          <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
            {view === "search-agent" && (
              <>
                {step === 0 && (
                  <UserRoleSelect
                    handleNextStep={handleNextStep}
                  />
                )}
                {step === 1 && (
                  <UserSearchByEmpNumber
                    searchType={ roleId === "7" ? "global-search" : "agent" }
                    multiMove={true}
                    isSearching={isSearching}
                    users={currentTeamMembers.filter(member => member.Role === getValues("MovementRole"))}
                    handleNextStep={handleNextStep}
                    userMoveRoleType={getValues("MovementRole")}
                  />
                )}
                {step === 2 && (
                  <UserSearchByEmpNumber
                    searchType="manager"
                    multiMove={false}
                    isSearching={isSearching}
                    handleNextStep={handleNextStep}
                    userMoveRoleType={getValues("MovementRole")}
                  />
                )}
                {step === 3 && (
                  <CampaignSearchByName
                    searchType="campaign"
                    isSearching={isSearching}
                    campaigns={managerCampaigns}
                    handleNextStep={handleNextStep}

                  />
                )}
              </>
            )}
          </SuiBox>
        </div>
      </SuiBox>

      <div className="button-section">
        {invalidRecords.length > 0 && <SuiBox className="error-tag" onClick={() => setShowInvalidRecords(true)}>
          <BiError />
          <SuiTypography color="error" variant="caption">Validation Errors Found</SuiTypography>
        </SuiBox>}
        <SuiTypography
          onClick={() => {
            setCurrentModal({});
          }}
          className="cancel-btn"
        >
          Cancel
        </SuiTypography>
        {/* <SuiButton
          form="modal-form"
          className="action-btn"
        >
          Continue
        </SuiButton> */}
        <button form="modal-form" className="btn btn-orange standard">
          Continue
				</button>
      </div>
      {showInvalidRecords && 
      <Card className="file-error-card">
        <SuiBox className='header'>
          <SuiTypography variant="h6">Validation Errors</SuiTypography>
          <AiOutlineClose className="close-btn" onClick={() => {setShowInvalidRecords(false)}}/>
        </SuiBox>
        <SuiBox className="error-content" sx={{ maxHeight: '200px', minWidth: '400px' }} >
          {invalidRecords.length > 0 && invalidRecords.map((record, index) => (
            <SuiTypography key={index} color="error" variant="overline">{record.description}</SuiTypography>
          ))}
        </SuiBox>
      </Card>}
    </div>
  );
}

export default UserMovementModal