import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import '../../styles/components/Modals.scss';


const UserMovementModal = ({children, style}) => {
  return (
    <div>
      <div className="r-modal-backdrop"></div>
      <div className={`r-modal ${style || ''}`}>
        {children}
      </div>
    </div>
  )
}

export default UserMovementModal