import React, { useEffect, useState, useRef } from "react";
import SuiInput from 'components/SuiInput'
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import CampaignList from 'examples/Lists/GenericItemList';
import '../../styles/components/User.scss'
import { isEmpty } from "lodash";
import Divider from "@mui/material/Divider";
import { useForm } from 'react-hook-form';
import SuiButton from "components/SuiButton";



const UserSearch = (props) => {
  const {searchType, campaigns, handleNextStep} = props;
  const { handleSubmit } = useForm();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const searchFieldRef = useRef(null);
  // const userList = useRef([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsMessage, setSearchResultsMessage] = useState('');
  const [campaignList, setCampaignList] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  
  //Status states

  const [searchError, setSearchError] = useState('');

  useEffect(() => {
    let tempCampaigns = campaigns.map((campaign) => ({
      ...campaign,
      isChecked: false
    }));
    setFilteredCampaigns(tempCampaigns);

  }, [campaigns])
  
  useEffect(() => {
    if(!isEmpty(searchField)){
      setSearchError('');
    }
  }, [searchField])

  const getSearchResults = async(input) => {

    if(input.length === 0){
      setFilteredCampaigns(campaigns);
    }

    if (input){
      setShowSuggestions(true);

      let tempArray = campaigns;
      let filteredUsers = tempArray.filter((campaign) => {
        return campaign.Type.includes(input);
      });

      if(filteredUsers.length > 0){
        setFilteredCampaigns(filteredUsers);
      }else{
        setFilteredCampaigns([]);
      }
      
    }else{
      setShowSuggestions(false);
    }
  };

  const updateChecklist = (campaign, checkedBool) => {
      for (let index = 0; index < filteredCampaigns.length; index++) {
        const element = filteredCampaigns[index];
        
        if(element.Code === campaign.Code){
          element.isChecked = checkedBool;
        }
      }
      setFilteredCampaigns([...filteredCampaigns]);    
  }

  const selectOrDeselectAll = (checkedBool) => {
    for (let index = 0; index < filteredCampaigns.length; index++) {
      const element = filteredCampaigns[index];
      
      element.isChecked = checkedBool;
    }
    setFilteredCampaigns([...filteredCampaigns]);
  }

  const onSubmit = () => {
    setFilteredCampaigns(campaigns);
    let selectedCampaigns = [];
    const tempArray = filteredCampaigns;
    for (let index = 0; index < tempArray.length; index++) {
      const campaign = tempArray[index];
      
      if (campaign.isChecked) {
        selectedCampaigns.push(campaign);
      }
    }
    handleNextStep(selectedCampaigns);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="modal-form" className='searchBy-campName-block'>
      <SuiBox className='search-container'>
        <div className="search-bar">
          <SuiInput placeholder={`Find ${searchType || 'user'} by name` }
                  onChange={(e) => { getSearchResults(e.target.value);}}
                  inputRef={searchFieldRef}
          />
          {/* <SuiButton disabled={isDisabled} onClick={() => getSearchResults()} color={'info'}>Search</SuiButton> */}
        </div>
        {searchError && <SuiTypography padding={2} color="error" variant="overline">{searchError}</SuiTypography>}

       {campaigns && <div className="table-container">
          <table className="table table-responsive">
            <thead>
              <tr>
                <th scope="col">
                  Select All
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="selectAll" onChange={(e) => e.target.checked ?  selectOrDeselectAll(true) : selectOrDeselectAll(false)} />
                    <label className="custom-control-label" htmlFor="selectAll"></label>
                  </div>
                </th>
                <th scope="col">Campaign Code</th>
                <th scope="col">Campaign Name</th>
              </tr>
            </thead>
            <tbody>
              {filteredCampaigns.length > 0 && filteredCampaigns.map((campaign, i) => (
                <tr key={i}>
                  <td>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id={`customCheck${i}`} onChange={(e) => updateChecklist(campaign, e.target.checked)} checked={filteredCampaigns[i].isChecked} />
                        <label className="custom-control-label" htmlFor={`customCheck${i}`}></label>
                    </div>
                  </td>
                  <td>{campaign.Code}</td>
                  <td>{campaign.Type}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>}
      </SuiBox>
    </form>
    
  )
}

export default UserSearch