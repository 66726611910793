import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { callMsGraph } from "../../graph";
import DashboardProvider from "../../providers/DashboardProvider";
import RoleIdProvider from "../../providers/RoleIdProvider"
import ProfileProvider from "../../providers/ProfileProvider"
import UsersProvider from "../../providers/UsersProvider"
import BeatLoader from "react-spinners/BeatLoader";

function Dashboard() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState({
    fullName: localStorage.getItem("fullName"),
    title: localStorage.getItem("title"),
    email: localStorage.getItem("email"),
    image: localStorage.getItem("image"),
    userPrincipalName: localStorage.getItem("userPrincipalName"),
  });
  const [dashboardData, setDashboardData] = useState([]);
  const [totalLeads, setTotalLeads] = useState();
  const [sales, setSales] = useState();
  const [callBack, setCallBack] = useState();
  const [talkTime, setTalkTime] = useState();
  const [followUp, setFollowUp] = useState();
  const [mealTime, setMealTime] = useState();
  const [conversionRate, setConversionRate] = useState();
  const [packageSales, setPackageSales] = useState();
  const [weightedSales, setWeightedSales] = useState();
  const [averageTalkTime, setAverageTalkTime] = useState();
  const [loading, setLoading] = useState(false);

  const UserPrincipalName = localStorage.getItem('userPrincipalName');

  const fetchDashboardData = async () => {
    if (UserPrincipalName !== undefined) {
      setLoading(true);
      let res = await DashboardProvider.GetDashboardData(UserPrincipalName);
      if (res.length > 0) {
        setTotalLeads(res[0]['totalLeads']);
        setSales(res[0]['sales']);
        setCallBack(res[0]['callBack']);
        setTalkTime(res[0]['talkTime']);
        setFollowUp(res[0]['followUp']);
        setMealTime(res[0]['mealTime']);
        setConversionRate(res[0]['conversionRate']);
        setPackageSales(res[0]['packageSales']);
        setWeightedSales(res[0]['weightedSales']);
        setAverageTalkTime(res[0]['averageTalkTime']);
      }
      setLoading(false);
    }
  }

  const initialzeGraphdata = () => {
    setDashboardData("res.totalLeads");
    setDashboardData("res.sales");
    setDashboardData("res.callBack");
    setDashboardData("res.talkTime");
    setDashboardData("res.mealTime");
    setDashboardData("res.conversionRate");
    setDashboardData("res.packageSales");
    setDashboardData("res.weightedSales");
    setDashboardData("res.averageTalkTime");
    if (isAuthenticated) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      if (graphData === null || localStorage.getItem("image") === null) {
        localStorage.clear();
        instance
          .acquireTokenSilent(request)
          .then(async (response) => {
              if(!localStorage.getItem('MSAuthToken')){
                let userSessionsResults = await UsersProvider.sendUserSession(response.account.username, response.accessToken);
                
                if (userSessionsResults.status === "success") {
                  localStorage.setItem("MSAuthToken", response.accessToken);
                }
                // else{
                //   alert({
                //     icon:'error',
                //     title: 'Failed MsAuth, You will be redirected to Login.',
                //   });
                //   setTimeout(logout , 3500);
                // } 
              }

            callMsGraph(response.accessToken).then(async (response) => {
              setGraphData(response);

              localStorage.setItem("email", response?.mail);
              localStorage.setItem("title", response?.jobTitle);
              localStorage.setItem("fullName", response?.displayName);
              localStorage.setItem("userPrincipalName", response?.userPrincipalName);
              if (response?.userPrincipalName != undefined && response?.userPrincipalName != null) {
                try {
                  DashboardProvider.PostLastLoginDate(response?.userPrincipalName);
                  const profile = await ProfileProvider.getProfile(response?.userPrincipalName);
                  localStorage.setItem("EmployeeNumber", profile?.EmployeeNumber || null);

                } catch (error) {
                  console.log(error)
                }
              }
            });
            callMsGraphProfilePicture(response.accessToken).then((picRes) => {
              localStorage.setItem("image", URL.createObjectURL(picRes));
            });
          })
          .catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
              callMsGraph(response.accessToken).then((response) =>
                setGraphData(response)
              );
            });
          });
      }
    } else {
      localStorage.clear();
    }
  }

  //Set states if not set previously
  useEffect(() => {
    if (!graphData.fullName && !graphData.email && !graphData.title && !graphData.image && !graphData.userPrincipalName){
      initialzeGraphdata();
    }
    fetchDashboardData(UserPrincipalName);
  }, [graphData, totalLeads, sales, callBack, talkTime, followUp, mealTime, conversionRate, packageSales, weightedSales, averageTalkTime])

  return (
    <div className={loading ? "Loader" : "Loader-Result"}>
      {
        loading ? <BeatLoader color={'#D0021B'} loading={loading} size={25} /> :
          <DashboardLayout>
            <DashboardNavbar />
            <SuiBox py={3}>
              <SuiBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} xl={2}>
                    <MiniStatisticsCard
                      title={{ text: "Total Leads" }}
                      count={totalLeads}
                      icon={{ color: "info", component: "notifications" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={2}>
                    <MiniStatisticsCard
                      title={{ text: "Sales" }}
                      count={sales}
                      icon={{ color: "info", component: "public" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={2}>
                    <MiniStatisticsCard
                      title={{ text: "CallBack" }}
                      count={callBack}
                      icon={{ color: "info", component: "emoji_events" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={2}>
                    <MiniStatisticsCard
                      title={{ text: "Talk Time" }}
                      count={talkTime}
                      icon={{
                        color: "info",
                        component: "shopping_cart",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={2}>
                    <MiniStatisticsCard
                      title={{ text: "Follow Up" }}
                      count={followUp}
                      icon={{ color: "info", component: "emoji_events" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={2}>
                    <MiniStatisticsCard
                      title={{ text: "meal Time" }}
                      count={mealTime}
                      icon={{ color: "info", component: "emoji_events" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={2}>
                    <MiniStatisticsCard
                      title={{ text: "conversion Rate" }}
                      count={conversionRate}
                      icon={{ color: "info", component: "emoji_events" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={2}>
                    <MiniStatisticsCard
                      title={{ text: "package Sales" }}
                      count={packageSales}
                      icon={{ color: "info", component: "emoji_events" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={2}>
                    <MiniStatisticsCard
                      title={{ text: "weighted Sales" }}
                      count={weightedSales}
                      icon={{ color: "info", component: "emoji_events" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={2}>
                    <MiniStatisticsCard
                      title={{ text: "average TalkTime" }}
                      count={averageTalkTime}
                      icon={{ color: "info", component: "emoji_events" }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
            </SuiBox>
            <Footer />
          </DashboardLayout>
      }
    </div>
  );
}

export default Dashboard;
