import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TeamMembersProvider from "providers/TeamMembersProvider";
import SuiTypography from "components/SuiTypography";
import Table from "examples/Tables/Table";
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";

const teamMembers = (props) => {
    const [rows, setRows] = useState([]);
    const [hover, setHover] = React.useState("#8392ab");

    function RowItem({ text }) {
        return (
            <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
                <SuiBox display="flex" flexDirection="column">
                    <SuiTypography variant="caption" color="secondary">
                        {text}
                    </SuiTypography>
                </SuiBox>
            </SuiBox>
        );
    }

    useEffect(async () => {
        if (localStorage.getItem('roleId') >= 2) {
            const response = await TeamMembersProvider.getTeamMembers();
            if (response.length > 0) {
                let temp = response;

                setRows(
                    temp.map((item) => ({
                        UserName: <RowItem text={item.UserName} />,
                        FullName: <RowItem text={item.FullName} />,
                        Employee_Number: <RowItem text={item.EmployeeNumber} />,
                    }
                    ))
                )
            }
        }

    }, []);

    const columns = [
        { name: "UserName", align: "left" },
        { name: "FullName", align: "left" },
        { name: "Employee_Number", align: "left" },
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox py={3}>
                <SuiBox sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                            borderBottom: ({
                                borders: { borderWidth, borderColor },
                            }) => `${borderWidth[1]} solid ${borderColor}`,
                        },
                    },
                }} mb={3}>
                    <Card>
                        <SuiBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            p={3}
                        >

                            <Grid container spacing={3} item xs={12} lg={12}>
                                {rows && <Table columns={columns} rows={rows} />}
                            </Grid>
                        </SuiBox>
                    </Card>
                </SuiBox>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default teamMembers;