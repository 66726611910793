import React, {useState, useEffect} from 'react'
import Select from "react-select";
import { useForm, useFormContext } from 'react-hook-form';
import SuiBox from 'components/SuiBox';

const UserRoleSelect = ({returnData, handleNextStep}) => {
  const { handleSubmit } = useForm();
  const { register, setValue } = useFormContext();
  const [roleOptions, setRoleOptions] = useState([]);

  const onSubmit = () => {
    if(typeof handleNextStep === 'function'){
        handleNextStep();
    }
  }

  useEffect(() => {
    let roleId = localStorage.getItem('roleId') || null;
    let roles = [];
    if (roleId) {
        if(parseInt(roleId, 10) >= 2){
            roles.push({
                value: 'Agent',
                label: 'Agent',
            })
        }
        if(parseInt(roleId, 10) >= 3){
            roles.push({
                value: 'Junior Manager',
                label: 'Junior Manager ( JM )',
            })
        }
        if(parseInt(roleId, 10) >= 4){
            roles.push({
                value: 'General Manager',
                label: 'General Manager ( GM )',
            })
        }
        if(parseInt(roleId, 10) >= 5){
            roles.push({
                value: 'Head of Sales',
                label: 'Head Of Sales ( HOD )',
            })
        }
        setRoleOptions(roles);
    }
    
  
  }, [])

  return (
    <form style={{width: '100%'}} onSubmit={handleSubmit(onSubmit)} id="modal-form">
        <SuiBox >
            <Select
                isSearchable={false}
                label={`Select the user role you wish to move?`}
                id={`userRole`}
                options={roleOptions}
                classNamePrefix="custom-select"
                className="select-control select-one select-with-html"
                register={register("MovementRole")}
                onChange={item => {
                    setValue("MovementRole", item.value);
                    // returnData(item.value)
                }}
            />
        </SuiBox>
    </form>
  )
}

export default UserRoleSelect