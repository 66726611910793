import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SuiBadge from "components/SuiBadge";
import SuiInput from "components/SuiInput";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import BeatLoader from "react-spinners/BeatLoader";
import ApprovalProvider from "providers/ApprovalProvider";

const today = new Date();
const time =
  today.getDate() + ":" + today.getMonth() + ":" + today.getFullYear();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Approvals() {
  const [approvalsChanged, setApprovalsChanged] = useState([
    today.getMilliseconds(),
  ]);
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = React.useState("#8392ab");


  const columns = [
    { name: "requester", align: "left" },
    { name: "approver", align: "left" },
    { name: "type", align: "left" },
    { name: "description", align: "left" },
    { name: "status", align: "center" },
    { name: "comments", align: "left" },
    { name: "action", align: "center" },
  ];

  const columns2 = [
    { name: "requester", align: "left" },
    { name: "approver", align: "left" },
    { name: "type", align: "left" },
    { name: "description", align: "left" },
    { name: "status", align: "center" },
    { name: "comments", align: "left" },
  ];

  const Approve = async (id, comment) => {
    const requestStatus = "APPROVED";
    await ApprovalProvider.approveOrRejected(id, requestStatus, comment);
    ApprovalProvider.actionApprovalRequest(id);
    setApprovalsChanged(new Date().getMilliseconds());
  };

  const Rejected = async (id, comment) => {
    const requestStatus = "REJECTED";
    await ApprovalProvider.approveOrRejected(id, requestStatus, comment);
    setApprovalsChanged(new Date().getMilliseconds());
  };

  useEffect(() => {
    fetchApprovals();
  }, [approvalsChanged]);

  const fetchApprovals = async () => {
    setLoading(true);
    let res = await ApprovalProvider.getApprovals();
    if (res.length > 0) {
      setLoading(false);
      const array = res.filter(
        (item) =>
          item.ApproverUserPrincipalName ==
          localStorage.getItem("userPrincipalName")
      );
      const array2 = res.filter(
        (item) =>
          item.RequesterUserPrincipalName ==
          localStorage.getItem("userPrincipalName")
      );
      setRows(
        array.map((item) => ({
          requester: <RowItem text={item.RequesterUserPrincipalName} />,
          approver: <RowItem text={item.ApproverUserPrincipalName} />,
          type: <RowItem text={item.RequestType} />,
          description: <RowItem text={item.RequestDescription} />,
          role: <Function job="Manager" org={item.ApproverUserPrincipalName} />,
          status: (
            <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
              <SuiBox display="flex" flexDirection="column">
                <SuiTypography
                  fontWeight={item.RequestStatus == "NEW" ? "bold" : "normal"}
                  fontSize={item.RequestStatus == "NEW" ? "15px" : "normal"}
                  variant="caption"
                  color="secondary"
                >
                  {item.RequestStatus}
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          ),
          comments: (
            <SuiTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              <SuiBox pt={0} px={0}>
                {item.ApproverComment != null ? (
                  <label
                    style={{ color: "#8392ab" }}
                    onChange={(e) => (item.comment = e.target.value)}
                    value={
                      item?.ApproverComment == null
                        ? null
                        : item.ApproverComment
                    }
                  >
                    {item.ApproverComment}
                  </label>
                ) : (
                  <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
                    <SuiInput
                      onChange={(e) => (item.comment = e.target.value)}
                      type="text"
                      name="comments"
                      value={
                        item?.ApproverComment == null
                          ? null
                          : item.ApproverComment
                      }
                      disabled={false}
                    />
                  </SuiBox>
                )}
              </SuiBox>
            </SuiTypography>
          ),
          action: (
            <SuiTypography
              component="a"
              href="#"
              variant="caption"
              color="secondary"
              fontWeight="medium"
              hidden={item.RequestStatus == "NEW" ? false : true}
            >
              <SuiTypography onClick={() => Approve(item.id, item?.comment)}>
                <span style={{ fontSize: "15px" }}>Approve</span>
              </SuiTypography>
              <Divider style={{ marginTop: "0rem", marginBottom: "0rem" }} />
              <SuiTypography onClick={() => Rejected(item.id, item?.comment)}>
                <span style={{ fontSize: "15px" }}>Reject</span>
              </SuiTypography>
            </SuiTypography>
          ),
        }))
      );
      setRows2(
        array2.map((item) => ({
          requester: <RowItem text={item.RequesterUserPrincipalName} />,
          approver: <RowItem text={item.ApproverUserPrincipalName} />,
          type: <RowItem text={item.RequestType} />,
          description: <RowItem text={item.RequestDescription} />,
          role: <Function job="Manager" org={item.ApproverUserPrincipalName} />,
          status: (
            <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
              <SuiBox display="flex" flexDirection="column">
                <SuiTypography
                  fontWeight={item.RequestStatus == "NEW" ? "bold" : "normal"}
                  fontSize={item.RequestStatus == "NEW" ? "15px" : "normal"}
                  variant="caption"
                  color="secondary"
                >
                  {item.RequestStatus}
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          ),
          comments: (
            <SuiTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              <SuiBox pt={0} px={0}>
                {item.ApproverComment != null ? (
                  <label
                    style={{ color: "#8392ab" }}
                    onChange={(e) => (item.comment = e.target.value)}
                    value={
                      item?.ApproverComment == null
                        ? null
                        : item.ApproverComment
                    }
                  >
                    {item.ApproverComment}
                  </label>
                ) : (
                  <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
                    <SuiInput
                      onChange={(e) => (item.comment = e.target.value)}
                      type="text"
                      name="comments"
                      value={
                        item?.ApproverComment == null
                          ? null
                          : item.ApproverComment
                      }
                      disabled={true}
                    />
                  </SuiBox>
                )}
              </SuiBox>
            </SuiTypography>
          ),
        }))
      );
    } else {
      setLoading(false)
      alert('Please contact Rewardsco Support!');
    }
  };

  function RowItem({ text }) {
    return (
      <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <SuiBox display="flex" flexDirection="column">
          <SuiTypography variant="caption" color="secondary">
            {text}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    );
  }

  function Function({ job, org }) {
    return (
      <SuiBox display="flex" flexDirection="column">
        <SuiTypography variant="caption" fontWeight="medium" color="text">
          {job}
        </SuiTypography>
        <SuiTypography variant="caption" color="secondary">
          {org}
        </SuiTypography>
      </SuiBox>
    );
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={loading ? "Loader" : "Loader-Result"}>
      {
        loading ? <BeatLoader color={'#D0021B'} loading={loading} size={25} /> :
          <DashboardLayout>
            <DashboardNavbar />
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  style={{ background: `${hover}` }}
                  onMouseEnter={() => setHover("#17a2b8")}
                  onMouseLeave={() => setHover("#17a2b8")}
                >
                  <Tab label="My Workflow" {...a11yProps(0)} />
                  <Tab label="My Request History" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <SuiBox py={3}>
                  <SuiBox mb={3}>
                    <Card>
                      <SuiBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={3}
                      >
                        <SuiTypography variant="h6">My Workflow</SuiTypography>
                      </SuiBox>
                      <SuiBox
                        sx={{
                          "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                              borderBottom: ({
                                borders: { borderWidth, borderColor },
                              }) => `${borderWidth[1]} solid ${borderColor}`,
                            },
                          },
                        }}
                      >
                        <Table columns={columns} rows={rows} />
                      </SuiBox>
                    </Card>
                  </SuiBox>
                </SuiBox>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <SuiBox py={3}>
                  <SuiBox mb={3}>
                    <Card>
                      <SuiBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={3}
                      >
                        <SuiTypography variant="h6">My Request History</SuiTypography>
                      </SuiBox>
                      <SuiBox
                        sx={{
                          "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                              borderBottom: ({
                                borders: { borderWidth, borderColor },
                              }) => `${borderWidth[1]} solid ${borderColor}`,
                            },
                          },
                        }}
                      >
                        <Table columns={columns2} rows={rows2} />
                      </SuiBox>
                    </Card>
                  </SuiBox>
                </SuiBox>
              </TabPanel>
            </Box>
            <Footer />
          </DashboardLayout>
      }
    </div>
  );
}

export default Approvals;
