import { isEmpty } from "lodash";
import Modal from '../../components/Modals/ModalSkeleton';
import UsersToMoveContentBlock from '../../components/ContentBlocks/TeamManagement/UsersToMoveNew';
import UsersToRemoveContentBlock from '../../components/ContentBlocks/TeamManagement/UserToRemove';
import UsersToAddContentBlock from '../../components/ContentBlocks/TeamManagement/UsersToAdd';
import ApproverContentBlock from '../../components/ContentBlocks/TeamManagement/ApproverNew';

const ContentBlockRenderer = ({currentModal, setCurrentModal, categoryType, requestType}) => {
    const DisplayContent = ({modalKey, view}) => {  
        switch (modalKey) {
          case "initiateMove":
            return (
              <UsersToMoveContentBlock
                view={view}
                setCurrentModal={setCurrentModal}
                categoryType={categoryType}
                requestType={requestType}
              />
            );

          case "initiateAdd":
            return (
              <UsersToAddContentBlock
                view={view}
                setCurrentModal={setCurrentModal}
                categoryType={categoryType}
                requestType={requestType}
              />
            );

          case "initiateRemove":
            return (
              <UsersToRemoveContentBlock
                view={view}
                setCurrentModal={setCurrentModal}
                categoryType={categoryType}
                requestType={requestType}
              />
            );

          case "moveApprover":
            return (
              <ApproverContentBlock
                view={view}
                setCurrentModal={setCurrentModal}
                categoryType={categoryType}
                requestType={requestType}
              />
            );

          default:
            return null;
        }
    }

  return (
    <>
    {!isEmpty(currentModal) && <Modal>
          <DisplayContent {...currentModal}/>
        </Modal>}
    </>
  )
}

export default ContentBlockRenderer