/**
 * Page loader component
 */
import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

function PageLoader(props) {
  return (
    <div className='page-loader'>
      <BeatLoader color={'#D0021B'} loading={props.loading} size={25} />
    </div>
  );
}

export default PageLoader;

