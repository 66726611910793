import React from "react";
import _ from "lodash";
import { map, isEmpty } from "lodash";
import moment from "moment"
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import CardHeader from "@mui/material/CardHeader";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BusinessIcon from "@mui/icons-material/Business";
import { Tree, TreeNode } from "react-organizational-chart";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { createTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    display: "inline-block",
    borderRadius: 16,
    boxShadow: "0px 7px 10px 0px rgba(0, 0, 0, 0.15)"
  },
  currentManager: {
    background: "transparent",
    display: "inline-block",
    borderRadius: 0
  },
  expand: {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: "#ECECF4"
  }
}));
const theme = createTheme({
  palette: {
    background: "#ffffff"   
  },
  fontFamily: "Roboto, sans-serif"
});

const Organization = ({ org }) => {
  const classes = useStyles();
  let backgroundColor = "white";
  return (<>
      <Card
         variant="outlined"
         className={classes.root}
         style={{ backgroundColor }}
       >
         <CardHeader
            avatar={
              <Avatar className={classes.avatar}></Avatar>
            }
            title={org.FullName}
            subheader={moment(org.date).format('DD/MM/YYYY')}
          />
         <Menu>
           <MenuItem>
             <ListItemIcon>
               <BusinessIcon color="primary" />
             </ListItemIcon>
             <ListItemText primary="Add Sub Profile" />
           </MenuItem>
           <MenuItem>
             <ListItemIcon>
               <AccountBalanceIcon color="secondary" />
             </ListItemIcon>
             <ListItemText primary="Add Sub Account" />
           </MenuItem>
         </Menu>
       </Card>
  </>);
}

const Agent = ({ a }) => {
  const classes = useStyles();
  let backgroundColor = "#13d4f4";
  let color ="white";
  return (
    <Card
      className={classes.root}
      style={{ cursor: "pointer", backgroundColor , color}}
    >
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}></Avatar>
        }
        title={a.FullName}
      />
    </Card>
  );
}

const Child = (props) => {
  return <Tree {...props} lineWidth={"2px"} lineColor={"#bbc"} lineBorderRadius={"12px"}>
    {props.children}
  </Tree>
}

const Node = ({ o, parent }) => {
  const T = parent ? TreeNode : Child;
  return (
    <TreeNode key={o.id} label={ <Organization org={o}/> }>
        {!isEmpty(o.Manager) ? <Node o={o.Manager} parent={o} /> : <TreeNode label={<Organization org={o}/>}></TreeNode>}
    </TreeNode>
  );
}

export default function UserHierarchyDiagram(props) {
  return (
           <ThemeProvider theme={theme}>
            <Box bgcolor="background" padding={4} height="80vh">
              <Tree label={<Organization org={props.userHierarchyInfo} />} lineWidth={"2px"} lineColor={"#bbc"} lineBorderRadius={"12px"}>
                <Node o={props.userHierarchyInfo.Manager} />
              </Tree>
            </Box>
          </ThemeProvider>
  );
}
