import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import SuiButton from "components/SuiButton";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

import UsersList from '../../../examples/Lists/UsersList';
import UserSearchByFileUpload from "../../User/UserByFileUpload.jsx";
import UserSearchByEmpNumber from "../../User/UserByEmpNumber.jsx";
import CampaignSearchByName from "../../Campaign/CampaignSearchByName.jsx";
import requesterRoleConfig  from '../../../static/configs/requesterRoleConfig.json';
import { AiOutlineClose } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import Card from "@mui/material/Card";
import UsersProvider from "../../../providers/UsersProvider.js";
import CampaignsProvider from "../../../providers/CampaignProvider.js";
import UserConfirmationList from "../../User/UserConfirmationList";
import UserRoleSelect from "../../../components/User/UserRoleSelect"
import { useFormContext } from 'react-hook-form';
import { IoChevronBackCircleOutline } from "react-icons/io5";


import {
  useSoftUIController,
  setRequestor,
} from "context";
import { isEmpty } from "lodash";
import teamMembers from "layouts/team-management";

const UserMovementModal = ({view, setCurrentModal, categoryType, requestType}) => {
  const { getValues, setValue } = useFormContext();
  const requiredFields = ['CampaignCode', 'EmployeeName', 'EmployeeCode', 'JuniorManagerEmployeeCode', 'JuniorManager'];
  const [controller, dispatch] = useSoftUIController();
  const { requestor } = controller;
  const [title, setTitle] = useState('');
  const [stepLimit, setStepLimit] = useState(0);
  const [step, setStep] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidRecords, setInvalidRecords] = useState([]);
  const [showInvalidRecords, setShowInvalidRecords] = useState(false);
  const [currentTeamMembers, setCurrentTeamMembers] = useState([]);
  const [userPrincipalName, setUserPrincipalName] = useState(localStorage.getItem('userPrincipalName'));

  const [fileRecords, setFileRecords] = useState([]);
  const [requestorObject, setRequestorObject] = useState({});

  // useEffect(() => {
  //   if(view === 'file-upload'){
  //     setStepLimit(1);
  //   }
  // }, []);

  const handleStep = (direction) => {

    if (step === stepLimit && direction === '+') {
      setCurrentModal({});
      return;
    }

    if(direction === '+'){
      setStep(step + 1);
    }
    else if(direction === '-'){
      if(step === 0) return;
      setStep(step - 1);
    }
  };

  const handleNextStep = async (formData) => {
    // if(view === 'file-upload'){
    //   switch (step) {
    //     case 0:
    //       break;

    //     case 1:
    //       break;
  
    //     case 2:
    //       saveToContext();
    //       break;
    
    //     default:
    //       setCurrentModal({});
    //       break;
    //   }
    // }
    if(view === 'search-agent'){
      switch (step) {
        case 0:
          saveToContext(formData);
          break;

        // case 1:
        //   break;

  
        default:
          setCurrentModal({});
          break;
      }
    }
	};

  const saveToContext = (formData) => {
    if(view === 'file-upload'){
      setValue("initiateRemove", requestorObject);
      setRequestor(dispatch, requestorObject);
      setCurrentModal({});
    }
    else if(view === 'search-agent'){
      const userSelected = setUserToRemove(formData);

      setRequestorObject({'initiateRemove': userSelected});
      setRequestor(dispatch, {'initiateRemove': userSelected});
      setValue("initiateRemove", userSelected);
      setCurrentModal({});
    }
  }

  const buildTeam = (members) => {
    let membersList = [];

    for (let index = 0; index < members.length; index++) {
      const member = members[index];
      membersList.push(member);
  
      if (member.SubordinateUsers && member.SubordinateUsers.length > 0) {
        membersList = membersList.concat(buildTeam(member.SubordinateUsers));
        delete member.subordinates;
      }
    }
    return membersList;
  }

  const getUsers = async() => {
    setIsSearching(true);
    try {
      const res = await UsersProvider.getUsersSearch({userPrincipalName: localStorage.getItem("userPrincipalName"), heirarchyLevel: 3});
      if(res.length > 0){
        let teamMembersDemo = buildTeam(res[0].SubordinateUsers);
        setCurrentTeamMembers(teamMembersDemo);
      }
      
    } catch (error) {
      
    }
    setIsSearching(false);
  }

  const setUserToRemove = (users) => {
    if(users && users.length > 0){
      let structuredUsers = users;
      if(users.length > 0){
         structuredUsers = users.map((user) => ({
          EmployeeName: user.fullName,
          EmployeeNumber: user.employeeNumber,
          ManagerEmployeeNumber: localStorage.getItem("EmployeeNumber")
        }))
      }
      handleStep('+');
      return structuredUsers;
    }
  }

  useEffect(() => {
    const getData = async() => {
      // if(view === 'file-upload'){
      //   setStepLimit(2);
      //   switch (step) {

      //     case 0:
      //       setTitle('Which user role do you wish to move?');
      //       await getUsers();
      //       break;

      //     case 1:
      //       setTitle('Who would you like to move?');
      //       await getUsers();
      //       break;
  
      //     case 2:
      //       setTitle('Movement Confirmation:');
      //       break;
        
      //     default:
      //       setCurrentModal({});
      //       break;
      //   }
      // }
      if(view === 'search-agent'){
        setStepLimit(1);
        switch (step) {
          case 0:
            setTitle('Who would you like to remove?');
            await getUsers();
            break;
        
          default:
            setCurrentModal({});
            break;
        }
      }

    }
    getData();
  }, [step])

  return (
    <div>
        {step !== 0 && <SuiTypography 
            onClick={() => {
              handleStep('-');
            }}
            my={2} 
            display="flex" gap={1} 
            alignItems="center" 
            color="text" 
            fontWeight="medium" 
            variant="button"> 
              <IoChevronBackCircleOutline /> Back
        </SuiTypography>}
      <div className="header">
        <SuiTypography pb={2} variant="h6">{title}</SuiTypography>
      </div>

      <SuiBox>
        <div className="form-group">
          <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
            {/* {view === "file-upload" && (
              <>
                {step === 0 && (
                  <UserRoleSelect
                    handleNextStep={handleNextStep}
                  />
                )}
                {step === 1 && (
                  <UserSearchByFileUpload
                    requiredFields={requiredFields}
                    invalidRecords={invalidRecords}
                    setInvalidRecords={setInvalidRecords}
                    handleNextStep={handleNextStep}
                  />
                )}
                {step === 2 && (
                  <UserConfirmationList 
                    className="file-upload-block" 
                    groupedProfiles={requestorObject?.initiateMove}
                    handleNextStep={handleNextStep}
                  />
                )}
              </>
            )} */}
            {view === "search-agent" && (
              <>
                {step === 0 && (
                  <UserSearchByEmpNumber
                    searchType="agent"
                    multiMove={false}
                    isSearching={isSearching}
                    users={currentTeamMembers}
                    handleNextStep={handleNextStep}
                  />
                )}
              </>
            )}
          </SuiBox>
        </div>
      </SuiBox>

      <div className="button-section">
        {invalidRecords.length > 0 && <SuiBox className="error-tag" onClick={() => setShowInvalidRecords(true)}>
          <BiError />
          <SuiTypography color="error" variant="caption">Validation Errors Found</SuiTypography>
        </SuiBox>}
        <SuiTypography
          onClick={() => {
            setCurrentModal({});
          }}
          className="cancel-btn"
        >
          Cancel
        </SuiTypography>
        {/* <SuiButton
          form="modal-form"
          className="action-btn"
        >
          Continue
        </SuiButton> */}
        <button form="modal-form" className="btn btn-orange standard">
          Continue
				</button>
      </div>
      {showInvalidRecords && 
      <Card className="file-error-card">
        <SuiBox className='header'>
          <SuiTypography variant="h6">Validation Errors</SuiTypography>
          <AiOutlineClose className="close-btn" onClick={() => {setShowInvalidRecords(false)}}/>
        </SuiBox>
        <SuiBox className="error-content" sx={{ maxHeight: '200px', minWidth: '400px' }} >
          {invalidRecords.length > 0 && invalidRecords.map((record, index) => (
            <SuiTypography key={index} color="error" variant="overline">{record.description}</SuiTypography>
          ))}
        </SuiBox>
      </Card>}
    </div>
  );
}

export default UserMovementModal