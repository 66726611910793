import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import SuiButton from "components/SuiButton";

export const SignInButton = () => {
    const { instance } = useMsal();
    const handleLogin = () => {
        localStorage.clear();
        instance.loginRedirect(loginRequest)
            .catch(e => {
                console.log(e);
            });
    }

    return (
        <SuiButton variant="gradient" color="info" fullWidth onClick={() => handleLogin()}>
            sign in
        </SuiButton>
    )
}