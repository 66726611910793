import mtn from "assets/images/logos/mtn.png"
import telkom from "assets/images/logos/telkom.png"
import vodacom from "assets/images/logos/vodacom.png"
import { useState, useEffect } from "react";
import '../ListPopup/Popup.css'
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from "@mui/material";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ManagerProvider from '../../../../providers/ManagerProvider';
import CampaignProvider from '../../../../providers/CampaignProvider';
import { useSnackbar } from 'notistack';
import { CloseButton } from "react-bootstrap";


function Popup(props) {
  const userPrincipalName = localStorage.getItem('userPrincipalName');
  const [snackbar, setSnackbar] = useState({});
  const [open, setOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [confirmButton, setDisableConfirmButton] = useState(true);
  const [filteredResults, setFilteredResults] = useState([]);
  const [listData, setlistData] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  useEffect(() => {
    if (props.setData != undefined && props.setData.length > 0) {
      setItemSelected(props.setData)
    }

    if (props.header == "manager") { fetchManagers() }
    if (props.header == "campaign") { fetchCampaigns() }

  }, [props.trigger]);

  //Search Items
  const searchItems = (searchValue) => {
    setSearchInput(searchValue)

    if (props.header === "manager") {
      if (searchValue !== "" && searchValue !== undefined) {
        try {
          const filteredData = listData.filter((item) => {
            return Object.values(item.FullName).join('').toLowerCase().includes(searchValue.toLowerCase());
          })
          setFilteredResults(filteredData)
        } catch (e) { }
        //error
      }
      else {
        setFilteredResults(listData)
      }
    }

    if (props.header === "campaign") {
      if (searchValue !== "" && searchValue !== undefined) {
        try {
          const filteredData = listData.filter((item) => {
            return Object.values(item.Name).join('').toLowerCase().includes(searchValue.toLowerCase());
          })
          setFilteredResults(filteredData)
        } catch (e) { }
        //error
      }
      else {
        setFilteredResults(listData)
      }
    }
  }

  const addItem = (item) => {
    if (itemSelected.some(x => x.Id === item.Id)) {
      const newTaskArray = itemSelected.filter(task => task.Id !== item.Id);
      setItemSelected(newTaskArray);
    } else {
      if (props.header === "manager") {
        setItemSelected([{ Id: item.Id, Fullname: item.FullName }])
      }
      if (props.header === "campaign") {
        setItemSelected([...itemSelected, { Id: item.Id, Code: item.Code, Type: item.Name }])
      }
    }
  }

  //Save Items
  const save = () => {
    if (props.header === "manager" && itemSelected.length > 0 && props.manager[0].Id != undefined) {
      let managerToInsert = itemSelected[0].Id;
      if (itemSelected[0].Id == props.manager[0].Id) {
        enqueueSnackbar("Same Manager Selection!");
      } else {
        LogApprovalUpdateManager(managerToInsert);
      }
    }

    if (props.header === "campaign") {
      let campaignToInsert = []
      for (let i = 0; i < itemSelected.length; i++) {
        campaignToInsert.push(itemSelected[i].Code);
      }
      let data = JSON.stringify(campaignToInsert);
      LogApprovalUpdateCampaign(data);
    }
  }

  //Set List Images
  const setImage = (Name) => {
    if (props.header === "manager") {
      //do nothing
    } else if (props.header === "campaign") {
      return Name.toLowerCase().includes('mtn') ? mtn : Name.toLowerCase().includes('telkom') ? telkom : Name.toLowerCase().includes('vodacom') ? vodacom : null
    }
  }

  const renderHeader = (
    <div>
      <SuiBox flexDirection="row" display="flex" py={0} mb={1}>
        <SuiBox mr={2} pt={0} px={0}>
          <IconButton onClick={() => { props.setTrigger(false), setSearchInput(''), setDisableConfirmButton(true), setItemSelected([]) }}> <CloseButton /><span style={{ fontSize: "15px", paddingRight: "10px" }}></span></IconButton>
        </SuiBox>
        <SuiBox pt={0} px={0} >
          <SuiInput onChange={(e) => searchItems(e.target.value)} placeholder="Search..."></SuiInput>
        </SuiBox>
      </SuiBox>
    </div>
  );

  let output = searchInput === '' ? listData : filteredResults
  const renderBody = output.map((item) => (
    <div className={itemSelected.some(x => x.Id === item.Id) ? 'selected' : null} key={item.Id} sx={{ height: "100%" }} onClick={() => { setDisableConfirmButton(false), addItem(item) }}>
      <SuiBox pt={0} px={0} >
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {item.header}
        </SuiTypography>
      </SuiBox>
      <SuiBox component="li" display="flex" alignItems="center" py={1} mb={1}>
        <SuiBox mr={2}>
          <SuiAvatar src={setImage(item.Name)} alt="something here" variant="rounded" shadow="md" />
        </SuiBox>
        <SuiBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
          <SuiTypography variant="button" fontWeight="medium">
            {item.FullName}
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="medium">
            {item.Name}
          </SuiTypography>
          <SuiTypography variant="caption" color="text">
            {item.description}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </div>
  ));

  const fetchManagers = async () => {
    let res = await ManagerProvider.getManagers();
    setlistData(res)
  }

  const fetchCampaigns = async () => {
    let res = await CampaignProvider.getCampaigns();
    setlistData(res)
  };

  const LogApprovalUpdateManager = async (managerToInsert) => {
    const requestTypeValue = 'UPDATE MANAGER'
    var data = {
      UserPrincipalName: userPrincipalName,
      RequestData: managerToInsert,
      RequestTypeValue: requestTypeValue
    }
    // let res2 = await CampaignProvider.CreateApproval(data)
    let res = await ManagerProvider.updateUserManager(data);
    // props.manager[0].label = itemSelected[0].FullName
    if (res == "success") {
      enqueueSnackbar("Manager Update Successfully!")
      props.onChange(itemSelected)
      props.setTrigger(false);
    } else {
      enqueueSnackbar("Manager Update Failed!")
    }
  }

  const LogApprovalUpdateCampaign = async (campaignToInsert) => {
    const requestTypeValue = 'UPDATE CAMPAIGNS'
    var data = {
      UserPrincipalName: userPrincipalName,
      RequestData: campaignToInsert,
      RequestTypeValue: requestTypeValue
    }
    console.log(data)
    let res = await CampaignProvider.UpdateCampaigns(Campaign)
    // let res2 = await CampaignProvider.CreateApproval(data)
    if (res == "success") {
      enqueueSnackbar("Campaigns change request logged succesfully!")
      props.onChange(itemSelected);
      props.setTrigger(false);
    } else {
      enqueueSnackbar("Campaign change Failed!")
    }
  }

  return (props.trigger) ? (
    <div>
      <div className="popup">
        <div className="container">
          <div className="popup-header">
            {renderHeader}
          </div>
          <div className="popup-inner">
            {renderBody}
          </div>
          <div className="popup-footer">
            <SuiButton onClick={() => { save(), setDisableConfirmButton(true) }} className="confirm-btn" disabled={confirmButton} >Submit</SuiButton>
          </div>
        </div>
      </div>
    </div>
  ) : "";
}

export default Popup;