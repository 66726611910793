import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Popup from "../ListPopup/Popup";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
import ManagerProvider from "../../../../providers/ManagerProvider";
import ApprovalProvider from "providers/ApprovalProvider";

function ManagerCard() {
  // Declare States and Variables
  const [buttonPopup, setButtonPopup] = useState(false);
  const [manager, setManager] = useState([]);
  const [managerDisabled, setManagerDisabled] = useState(true);

  //MAKE PART OF THE STATE TO AVOID RERUNS
  let userManager = {};

  const fetchButtonConfig = async () => {
    let managerAllow = await ApprovalProvider.disallowActions('UPDATE MANAGER');
    console.log('SaTest', managerAllow)
    if (managerAllow?.ActionValue == 0) {
      setManagerDisabled(false);
    }
    // else {
    //   setManagerDisabled(false);
    // }
  }

  // API Calls
  const fetchUserManager = async () => {
    let res = await ManagerProvider.getUserManager();
    if (res.length > 0) {
      userManager.label = res[0]["FullName"]
      userManager.Id = res[0]["Id"]
      setManager([userManager])
    }
  }

  const changeHandler = () => {
    fetchButtonConfig();
  }

  // Render Calls
  useEffect(() => {
    fetchButtonConfig();
    fetchUserManager();
  }, []);

  const renderManager = manager.map((item) => (
    <div key={item.Id} sx={{ height: "100%" }}>
      <SuiBox component="li" display="flex" alignItems="center" py={3} mb={1}>
        <SuiBox mr={2}>
          <SuiAvatar
            src={item.image}
            alt="something here"
            variant="rounded"
            shadow="md"
          />
        </SuiBox>
        <SuiBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <SuiTypography variant="button" fontWeight="medium">
            {item.label}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </div>
  ));

  // Return
  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox p={2}>
        <SuiBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
        >
          <SuiBox component="li" display="flex" alignItems="center" py={0} mb={1}>
            <SuiTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
            >
              Manager
            </SuiTypography>

            <SuiBox ml="auto">
              <SuiButton
                onClick={() => setButtonPopup(true)}
                component={Link}
                variant="text"
                color="info"
                disabled={managerDisabled}
              >
                Update Manager
              </SuiButton>
            </SuiBox>
          </SuiBox>
          {renderManager}
        </SuiBox>
        {buttonPopup && <Popup
          onChange={changeHandler}
          trigger={buttonPopup}
          setTrigger={setButtonPopup}
          manager={manager}
          header={'manager'}
          setData={manager}>
        </Popup>}
      </SuiBox>
    </Card>
  );
}

ManagerCard.propTypes = {
  manager: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ManagerCard;