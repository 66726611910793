import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import axios from "axios";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ManagerCard from "./components/ManagerCard";
import CampaignCard from "./components/CampaignCard";
import Header from "layouts/profile/components/Header";
import { callMsGraph } from "../../graph";
import BeatLoader from "react-spinners/BeatLoader";
import { callMsGraphProfilePicture } from "../../graphProfilePicture";

function Overview() {
  const [employeeNumber, setemployeeNumber] = useState([])
  const [mobileNumber, setmobileNumber] = useState([])
  const [diallerUserName, setDiallerUserName] = useState('')
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState({
    fullName: localStorage.getItem("fullName"),
    title: localStorage.getItem("title"),
    email: localStorage.getItem("email"),
    image: localStorage.getItem("image"),
    diallerUserName: localStorage.getItem("diallerUserName"),
    userPrincipalName: localStorage.getItem("userPrincipalName"),
  });
  const [profileData, setprofileData] = useState({
    mobileNumber: localStorage.getItem("mobilePhone"),
    employeeNumber: localStorage.getItem("employeeNumber"),
    diallerUserName: localStorage.getItem("diallerUserName"),
  });
  const UserPrincipalName = localStorage.getItem("userPrincipalName");
  const [loading, setLoading] = useState(false);

  const initializeProfileData = async () => {
    setLoading(true);
    if (UserPrincipalName != null) {
      await axios.get(process.env.REACT_APP_AWS_API + '/Profile?UserPrincipalName=' + UserPrincipalName)
        .then(res => {
          setLoading(false);
          setmobileNumber(res.data[0]["MobileNumber"])
          setemployeeNumber(res.data[0]["EmployeeNumber"])
          setDiallerUserName(res.data[0]["DiallerUserName"])
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
          alert('Error getting profile data for ' + UserPrincipalName + '. Please contact Rewardsco Support!');
        })
    }
  }

  const initialzeGraphdata = () => {
    if (isAuthenticated) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      if (graphData === null || localStorage.getItem("image") === null) {
        instance
          .acquireTokenSilent(request)
          .then((response) => {
            callMsGraph(response.accessToken).then((response) => {
              setGraphData(response);
              localStorage.setItem("email", response?.mail);
              localStorage.setItem("title", response?.jobTitle);
              localStorage.setItem("fullName", response?.displayName);
              localStorage.setItem("userPrincipalName", response?.userPrincipalName);
              localStorage.setItem("mobilePhone", response?.mobileNumber);
              initializeProfileData();
            });
            callMsGraphProfilePicture(response.accessToken).then((picRes) => {
              // console.log(URL.createObjectURL(picRes));
              // console.log(picRes);
              localStorage.setItem("image", URL.createObjectURL(picRes));
            });
          })
          .catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
              callMsGraph(response.accessToken).then((response) =>
                setGraphData(response)
              );
            });
          });
      }
    } else {
      localStorage.clear();
    }
  }

  useEffect(() => {
    if (!graphData.fullName && !graphData.email && !graphData.title && !graphData.image && !graphData.userPrincipalName) {
      initialzeGraphdata();
    }
    if (mobileNumber.length == 0 && employeeNumber.length == 0 && diallerUserName.length == 0) {
      initializeProfileData();
    }
  }, [graphData, profileData]);

  if (localStorage != null) {
    return (
      <div className={loading ? "Loader" : "Loader-Result"}>
        {
          loading ? <BeatLoader color={'#D0021B'} loading={loading} size={25} margin={2} /> :
            <DashboardLayout>
              <Header />
              <SuiBox mt={5} mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} xl={6}>
                    <ProfileInfoCard
                      title="profile information"
                      info={{
                        fullName: graphData?.fullName,
                        title: graphData?.title,
                        email: graphData?.email,
                        employeeNumber: employeeNumber,
                        mobilePhone: mobileNumber,
                        diallerUserName: diallerUserName
                      }}
                      action={{ route: "", tooltip: "Edit Profile" }}
                    />
                  </Grid>
                  <Grid item xs={12} xl={6}>
                    <CampaignCard />
                  </Grid>
                  <Grid item xs={12} xl={6}>
                    <ManagerCard />
                  </Grid>
                </Grid>
              </SuiBox>
              <Footer />
            </DashboardLayout>
        }
      </div>
    );
  }
}

export default Overview;