import Swal from 'sweetalert2'

const useAlert= () => {
    const alert = ({icon, title, text, footer}) => {
        let alertObj = {};
        icon ? alertObj.icon = icon : '';
        title ? alertObj.title = title : '';
        text ? alertObj.text = text : '';
        footer ? alertObj.footer = footer : '';

        Swal.fire(alertObj);
    }

  return { alert };

}

export default useAlert;

