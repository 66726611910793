const jwt = require('jsonwebtoken');
const Cryptr = require('cryptr');
const CRYPTR_KEY = 'LfFLf8cAAABwvgp';

export function getJWToken(data, expiresIn = '8h') {
    const jwtSecret = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9';
    return jwt.sign(data, jwtSecret, {
        expiresIn,
    });
}

export function encrypt(val) {
    const cryptr = new Cryptr(CRYPTR_KEY);
    return cryptr.encrypt(val);
}

export function decrypt(encrypted) {
    const cryptr = new Cryptr(CRYPTR_KEY);
    return cryptr.decrypt(encrypted);
}