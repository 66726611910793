import axios from 'axios'

class ManagerProvider {
  static async getUserManager() {

    let results = []

    await axios.get(process.env.REACT_APP_AWS_API + '/Manager?UserPrincipalName=' + localStorage.getItem('userPrincipalName'))
      .then(res => {
        if (res.data.length > 0) {
          results = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return results;
  }


  static async getManagers() {
    let results = []
    await axios.get(process.env.REACT_APP_AWS_API + '/Managers?Role=Agent')
      .then(res => {
        if (res.data.length > 0) {
          results = res.data;
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }

  static async updateUserManager(data) {
    // debugger
    let results = []
    await axios.post(process.env.REACT_APP_AWS_API + '/Update/Manager', data)
      .then(res => {
        // debugger;
        if (res.data.status == "success") {
          results = "success"
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }

}

export default ManagerProvider;
