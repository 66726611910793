/**
 * Page loader component
 */
import SuiTypography from "components/SuiTypography";
import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

function DefaultComponentLoader(props) {
  return (
    <div className='component-loader'>
      <BeatLoader color={'#D0021B'} loading={props?.loading} size={props?.size || 25} />
      {props?.message && <SuiTypography variant="button" fontWeight="normal">{props?.message}</SuiTypography>}
    </div>
  );
}

export default DefaultComponentLoader;

