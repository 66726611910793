import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import NotificationCard from "./notificationCard";
import ivancik from "assets/images/ivancik.jpg";
import NotificationProvider from "../../../../providers/NotificationProvider";
import moment from "moment";
import BeatLoader from "react-spinners/BeatLoader";

const today = new Date();
const time =
  today.getDate() + ":" + today.getMonth() + ":" + today.getFullYear();

function UserNotifications(props) {
  let array = [];
  const [userNotifications, setUserNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchNotifications = async () => {
    let cache = null;
    if (localStorage.getItem("userNotifications") != null) {
      cache = JSON.parse(localStorage.getItem("userNotifications"));
      array = cache["data"];
    }

    if (time > cache?.date || cache == null) {
      setLoading(true);
      let res = await NotificationProvider.getUserNotifications();
      if (res.length > 0) {
        setLoading(false);
        array = res;
        setUserNotifications(array);
        localStorage.setItem(
          "userNotifications",
          JSON.stringify({ date: time, data: res })
        );
        console.log(res.statusCode)
      } else {
        setLoading(false)
        alert('No Notifications found.');
      }
    } else {
      setUserNotifications(array);
    }
  };

    useEffect(() => {
        fetchNotifications();
    }, [userNotifications]);

  const renderNotifications = userNotifications.map(
    ({ id, category, Subject, Content, createddate, ReadDate }) => (
      <div key={id} sx={{ height: "100%" }}>
        <NotificationCard
          id={id}
          subject={Subject}
          category={category}
          content={Content}
          createddate={moment(createddate).utc().format('DD/MM/YYYY')}
          readDate={ReadDate}
          noGutter
        />
      </div>
    )
  );

  return (
    <div className={loading ? "Loader" : "Loader-Result"}>
      {
        loading ? <BeatLoader color={'#D0021B'} loading={loading} size={25} /> :
          <Card sx={{ height: "100%" }}>
            <SuiBox position="relative" height="100%" p={2}>
              <SuiBox
                display="flex"
                flexDirection="column"
                height="100%"
                py={2}
                px={2}
                borderRadius="lg"
                sx={{
                  backgroundImage: ({
                    functions: { linearGradient, rgba },
                    palette: { gradients },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${ivancik})`,
                  backgroundSize: "cover",
                }}
              >
                <SuiBox pt={1} pb={2} px={2}>
                  <SuiBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                  >
                    {renderNotifications}
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </SuiBox>
          </Card>
      }
    </div>
  );
}

Notification.PropTypes = {
  Subject: PropTypes,
};

export default UserNotifications;
