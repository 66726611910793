import axios from 'axios'

class CampaignProvider {

  static async getUserCampaigns(userPrincipalName) {
    let results = []
    await axios.get(process.env.REACT_APP_AWS_API + '/Campaign?UserPrincipalName=' + userPrincipalName)
      .then(res => {
        if (res.data.length > 0) {
          results = res.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }

  static async getUserCampaignsByReference({Id}) {
    let results = []
    await axios.get(process.env.REACT_APP_AWS_API + '/GetUserCampaigns?Id=' + Id)
      .then(res => {
        if (res.data.length > 0) {
          results = res.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }

  static async getCampaigns() {
    let results = []
    await axios.get(process.env.REACT_APP_AWS_API + '/Campaigns')
      .then(res => {
        // debugger;
        if (res.data.length > 0) {
          results = res.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }

  static async UpdateCampaigns(data) {
    let results = []
    await axios.post(process.env.REACT_APP_AWS_API + '/DeleteInsert/Campaign', data)
      .then(res => {
        if (res.data.status == "success") {
          results = "success"
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }

  static async CreateApproval(data) {
    let results = []
    await axios.post(process.env.REACT_APP_AWS_API + '/CreateApproval', data)
      .then(res => {
        if (res.data.status == "success") {
          results = "success"
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }

  //  static async insertUserManager(data) {
  //   let results = []
  //  await axios.post(Config.baseURL+ '/Update/Campaign', data)
  //   .then(res=>{
  //     // debugger;
  //     if (res.data.status == "success") {
  //         results = "success"
  //       }
  //   })
  //   .catch(err=>{
  //     console.log(err)
  // })  
  // return  results
  // }

  // static async deleteUserManager(data) {
  //   // debugger
  //   let results = []
  //  await axios.post(Config.baseURL+ '/Delete/Campaign', data)
  //   .then(res=>{
  //     // debugger;
  //     if (res.data.status == "success") {
  //         results = "success"
  //       }
  //   })
  //   .catch(err=>{
  //     console.log(err)
  // })  
  // return  results
  // }
  // }
}

export default CampaignProvider;
