import axios from 'axios';

class MovementReasonProvider {

    static async getMovementReasons(requestType) {
        let results = []
        let url = ''
        url += process.env.REACT_APP_AWS_API + '/GetUserHierarchyMovementReasons';
        requestType ? url += `?RequestType=${requestType}`: '';

        await axios.get(url , 
        {headers: {'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY }})
            .then(res => {
                if (res.data.length > 0) {
                    results = res.data;
                }
            })
            .catch(err => {
                console.log(err);
            })
        return results;
    }
}

export default MovementReasonProvider;