import React from "react";
import { useMsal } from "@azure/msal-react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";
export const SignOutButton = () => {
    const { instance } = useMsal();
    const handleLogout = () => {
        localStorage.clear();
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }
    return (
        <DropdownButton variant="secondary" className="ml-auto" drop="left" title="Sign Out">
            <Dropdown.Item as="button" onClick={() => handleLogout()}>Sign out using Redirect</Dropdown.Item>
        </DropdownButton>
    )
}