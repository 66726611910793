import axios from 'axios'

class NotificationProvider {
  
  static async getUserNotifications() {
    let results = []
     await axios.get(process.env.REACT_APP_AWS_API+'/UserNotifications?UserPrincipalName=' + localStorage.getItem('userPrincipalName'))
      .then(res=>{
        if (res.data.length > 0) {
            results = res.data
          }
      })
      .catch(err=>{
        console.log(err)
    })  
    return results;
  }

  static async updateReadDate(id) {
    let results;
     await axios.post(process.env.REACT_APP_AWS_API+'/UserNotifications?UserPrincipalName=' + localStorage.getItem('userPrincipalName') + '&id=' + id)
      .then(res=>{
        if (res.data.length > 0) {
            results = res.data
          }
      })
      .catch(err=>{
        console.log(err)
    })  
    return results;
  }
}

export default NotificationProvider;
 