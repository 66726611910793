import { useMsal } from "@azure/msal-react";

const useAuth = () => {
    const { instance } = useMsal();

    const logout = () => {
        localStorage.clear();
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }

  return { logout };

}

export default useAuth;

