import React from 'react'
import UsersList from '../../examples/Lists/UsersList';
import { useForm } from 'react-hook-form';


const UserConfirmationList = ({className, groupedProfiles, profiles, handleNextStep}) => {
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    if(typeof handleNextStep === 'function'){
        handleNextStep();
    }
  }
    
  return (
    <form onSubmit={handleSubmit(onSubmit)} id="modal-form" className={className || ''}>
        {(groupedProfiles || profiles) && <UsersList
            className="list"
            groupedProfiles={groupedProfiles}
            profiles={profiles}
            // removeUserFromList={removeUserFromList}
        />}
    </form>
  )
}

export default UserConfirmationList