import axios from 'axios';

class ProfileProvider {

  static async getProfile(UserPrincipalName) {
    return axios.get(process.env.REACT_APP_AWS_API + '/Profile?UserPrincipalName=' + UserPrincipalName,
        {headers: {'x-api-key': process.env.REACT_APP_AGENT_HIERARCHY_KEY }})
        .then(json => json.data)
        .then(res => {
          return (res && res.length > 0) ? res[0] : {};
        })
        .catch(err => {
          console.log(err);
          return {};
        });
  }

  static async updateMobileNumber(MobileNumber) {
 
    const data = {
      UserPrincipalName: localStorage.getItem("userPrincipalName"),
      MobileNumber: MobileNumber
    }
    
    let results = []
    await axios.post(process.env.REACT_APP_AWS_API + '/Update/Profile', data)
      .then(res => {
        if (res.data.status == "success") {
          results = "success"
        }
      })
      .catch(err => {
        console.log(err)
      })
    return results
  }
}

export default ProfileProvider;
