import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import UserNotifications from "layouts/dashboard/components/UserNotifications";
import React from "react";

function Overview() {
  if(localStorage != null) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <UserNotifications />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
        <Footer />
      </DashboardLayout>
    );
  }
}

export default Overview;