import React, { useCallback, useState, useEffect, useRef } from 'react';
import { AiOutlineCloudUpload, AiOutlineClose } from 'react-icons/ai';
import {useDropzone} from 'react-dropzone';
import Papa from "papaparse";
import { has } from 'lodash';
import SuiTypography from 'components/SuiTypography';
import { useForm } from 'react-hook-form';


const UserSearch = ({ className, requiredFields, invalidRecords, setInvalidRecords, handleNextStep , externalValidation}) => {
  const { handleSubmit } = useForm();
  const [files, setFiles] = useState([]);
  const [filesMessage, setFilesMessage] = useState({});
  const csvToJsonObject = useRef([]);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setFilesMessage({});
      setFiles(acceptedFiles);
    }else{
      // setFiles([]);
      setFilesMessage({type: 'error', description: 'Incorrect filetype (Required: .csv)'});
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: {'text/csv': ['.csv']}, minSize: 0, maxSize: 2048 });


  const readUploadFile = (files) => {
    if (files) {
      Papa.parse(files[0], {
        delimiter: ';',
        header: true,
        complete: function(results) {          
          csvToJsonObject.current = results.data;
          validateJSONStructure(csvToJsonObject.current);
        }}
      )
    }
  }

  const validateJSONStructure = (json) => {
    setInvalidRecords([]);
    let incorrectRecords = [];

    for (let index = 0; index < json.length; index++) {
      const record = json[index];

      if(record?.CampaignCode) record.CampaignCode = record.CampaignCode.split(",")
      for (let field of requiredFields) {
        if (!has(record, field)) {
          if(index === json.length -1){
            json.pop();
            break;
          }
          // incorrectRecords.push({row: index + 1, field: field});
          incorrectRecords.push({description: `Row ${index + 1} is missing ${field} field.`});
        }
      }
    }
    setInvalidRecords(incorrectRecords);
    if(typeof externalValidation === 'function'){
      externalValidation(json);
      if (!externalValidation) {
        return;
      }
    }

    if(incorrectRecords.length === 0){
      csvToJsonObject.current = json;
      setFilesMessage({type: 'success', description: files[0].name})
    }
  }
  
  useEffect(() => {
    if(files.length > 0){
      readUploadFile(files);
    }
  }, [files])
 
  const onSubmit = () => {
    if(invalidRecords.length === 0 && files.length === 1){
      handleNextStep(csvToJsonObject.current);
    }
  }
  
  return (
    <div className='file-upload-block'>
      <form onSubmit={handleSubmit(onSubmit)} id="modal-form">
        <div {...getRootProps({className: className})} className='drag-drop-window'>
          <input {...getInputProps({accept: ['csv']})} />
          <div className='content'>
            <AiOutlineCloudUpload className='icon' />
          { isDragActive ? (
            <p>Drag and drop a file, or browse</p>
          ) : (
            <>
              <p>Drag and drop files, or click to browse files</p>
              <SuiTypography fontWeight="bold" color={invalidRecords.length > 0 ? 'error' : filesMessage.type} variant="overline">{filesMessage.description}</SuiTypography>
            </>
          )}
        </div>
      </div>
      </form>
    </div>
  );
}

export default UserSearch