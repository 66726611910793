import React from 'react';
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import NotificationProvider from 'providers/NotificationProvider';

function NotificationCard({ id, subject, content, category, createddate, readDate, expand, noGutter }) {

  const Update = (id) => {
    UpdateReadDate(id);
  };

  const CheckColor = (readDate) => {
    if(readDate != null) {
      return "green";
    }
    else {
      return "red";
    }
  };

  const UpdateReadDate = async (id) => {
    await NotificationProvider.updateReadDate(id);
    localStorage.removeItem('userNotifications');
  };

  return (
    <SuiBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor="grey-100"
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <SuiBox width="100%" display="flex" flexDirection="column">
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <SuiTypography
            variant="button"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {subject}
          </SuiTypography>

          <SuiBox
            display="flex"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
          >
            <SuiButton variant="text" color="dark">
              <Icon style={{color: CheckColor(readDate)}} onClick={() => Update(id)}>email</Icon>&nbsp;
            </SuiButton>
            
          </SuiBox>
        </SuiBox>

        <SuiBox mb={1} lineHeight={0}>
          <SuiTypography variant="caption" color="text">
            &nbsp;&nbsp;&nbsp;
            <SuiTypography
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {category}
            </SuiTypography>
          </SuiTypography>
        </SuiBox>

        <SuiBox mb={1} lineHeight={0}>
          <SuiTypography variant="caption" color="text">
            &nbsp;&nbsp;&nbsp;
            <SuiTypography variant="caption" fontWeight="medium">
              {content}
            </SuiTypography>
          </SuiTypography>
        </SuiBox>

        <SuiTypography variant="caption" color="text">
          &nbsp;&nbsp;&nbsp;
          <SuiTypography variant="caption" fontWeight="medium">
            Created: {createddate}
          </SuiTypography>
        </SuiTypography>
        <SuiBox mb={1} lineHeight={1}>
          <SuiTypography variant="caption" color="text">
            &nbsp;&nbsp;&nbsp;
            <SuiTypography variant="caption" fontWeight="bold">
              {expand}
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

NotificationCard.defaultProps = {
  noGutter: false,
};

NotificationCard.propTypes = {
  id: PropTypes.number.isRequired,
  subject: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  createddate: PropTypes.string.isRequired,
  readDate: PropTypes.string.isRequired,
  expand: PropTypes.string.isRequired,
  noGutter: PropTypes.bool
};

export default NotificationCard;