import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function updateBusinessPhone(accessToken,data) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    // debugger;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json")
    

    const options = {
        method: "PATCH",
        headers: headers,
        body : JSON.stringify({  businessPhones: ['+1 425 555 0109']})
    };

    const user = {
        businessPhones: [
          data
        ]
      };

      //Unable to update the specified properties for on-premises mastered Directory Sync objects or objects currently undergoing migration



    //   await client.api('/me')
    //   .update(user);
    // fetch("https://jsonplaceholder.typicode.com/posts/1", {  method: "PATCH",  headers: {    "Content-type": "application/json"  },  body: JSON.stringify({    title: "Corrected post"  })}) .then(response => {    console.log(response.status);     return response.json();  })  .then(data => console.log(data));
    return fetch(graphConfig.graphMeEndpoint , options)
        .then(response => response.json())
        .catch(error => console.log(error));

    
}
